import { Fraction } from "fractional";
import styled from "styled-components";
import { IUNIT } from "../../Data/Constants";
import { useLocalStorage } from "../../Hooks/UseLocalStorage";
import Checkbox from "./Checkbox";

const getUnitDescription = (unit, amount) => {
  switch (unit) {
    case IUNIT.DL:
      return "dl";
    case IUNIT.L:
      return "liter";
    case IUNIT.TBLSP:
      return "spsk";
    case IUNIT.TSP:
      return "tsk";
    case IUNIT.SMIDGEN:
      return "1 knivspids";
    case IUNIT.G:
      return "g";
    case IUNIT.KG:
      return "kg";
    case IUNIT.CAN:
      return amount === 1 ? "dåse" : "dåser";
    default:
      return "";
  }
};

const getAmount = (amount, unit, customSize, recipeSize) => {
  if (unit === IUNIT.SMIDGEN) {
    return "";
  }
  if (unit === IUNIT.G) {
    return Math.floor((amount * customSize) / recipeSize);
  } else {
    return new Fraction(amount)
      .multiply(customSize)
      .divide(recipeSize)
      .toString();
  }
};

const IngredientWrapper = styled.div`
  margin-bottom: 0.5em;
  > div {
    margin-left: 3px;
    margin-right: 8px;
  }
`;

export const Ingredient = ({
  uniqueKey,
  amount,
  unit,
  description,
  customSize,
  recipeSize,
}) => {
  const localStorageKey = "ic_" + uniqueKey;

  const [checked, setChecked] = useLocalStorage(localStorageKey, false);
  return (
    <IngredientWrapper>
      <Checkbox
        checked={checked}
        onClick={() => {
          setChecked(!checked);
        }}
      />
      <span style={{ color: checked ? "gray" : "inherit" }}>
        {amount ? getAmount(amount, unit, customSize, recipeSize) + " " : ""}
        {unit ? getUnitDescription(unit, amount, "dk") + " " : ""}
        {description ? description["dk"] : ""}
      </span>
    </IngredientWrapper>
  );
};
