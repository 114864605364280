import styled from "styled-components";
import billedeAfMig from "./images/billede af mig.jpg";
import img19 from "./images/minder.jpg";
import img20 from "./images/Rectangle 20.jpg";
import img21 from "./images/Rectangle 21.jpg";
import img22 from "./images/Rectangle 22.jpg";
import { useState } from "react";
import { Image, Images, LinkWrapper } from "../../../Components/CommonStyles";
import { Email } from "../About";

const PillShapedButton = styled.div`
  background-color: none;
  border: 1px solid black;
  color: black;
  padding: 17px 40px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 80px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 60px;
  position: relative;
  left: 50%;
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
`;

function Isa() {
  const [showEmail, setShowEmail] = useState(false);
  const email = "IsaCarstens@gmail.com";
  return (
    <>
      <h2>om Isa</h2>

      <img
        src={billedeAfMig}
        style={{
          float: "right",
          margin: "0px 0px 22px 22px",
          maxWidth: "100%",
          height: "auto",
        }}
      />
      <p>
        Hej, mit navn er Isa og jeg vil gerne byde dig velkommen til mit lille
        hjørne på internettet, grønne stunder.
      </p>
      <p>
        Udover at lave mad, holder jeg af overskyet vejr og alt der rimer på
        æstetik. En gang imellem sætter jeg mig ned for at male eventyrlige
        motiver med akvarel eller skrive lyrik.
      </p>
      <p>
        Når jeg står i køkkenet følger jeg sjældent en opskrift, men foretrækker
        at prøve mig frem. For mig er madlavning en mulighed for at udfolde sig
        kreativt, og ikke nødvendigvisbare et sæt regler der skal følges.
      </p>
      <p>
        Hver aftensmad kan naturligvis ikke være den store kreative udfoldelse,
        og måske er det netop dét der er givende ved madlavning som kreativ
        proces; der er ikke en forventning om at skabe noget nyt hver gang, og
        nogle gange er det bare de små variationer i en opskrift, der gør
        forskellen.
      </p>
      <p>
        Jeg håber at mine opskrifter vil give dig lyst til at prøve
        plantebaseret mad samt opfordre dig til at eksperimentere med
        madlavningen.
      </p>
      <p>
        Fremtiden både skræmmer og glæder mig, og er noget jeg bruger meget
        tankekraft på. Hvordan kan en hel tid der ikke er sket, frembringe så
        kraftige følelser af både frygt og håb? Til næste år vil min
        lyriksamling ‘minder fra fremtiden’ komme til verden. Teksterne
        beskæftiger sig med forandring, uvished og håb; et blik ind i et ungt
        sind.
      </p>
      <p>Her er en visuel forsmag</p>
      <Images>
        <LinkWrapper to={"/minderfrafremtiden"}>
          <Image src={img19} />
        </LinkWrapper>
        <div>
          <Image src={img20} />
        </div>
        <div>
          <Image src={img22} />
        </div>
        <div>
          <Image src={img21} />
        </div>
      </Images>
      <PillShapedButton
        onClick={(e) => {
          setShowEmail(true);
          e.preventDefault();
        }}
      >
        Bestil akvarelmaleri eller indbydelse her
      </PillShapedButton>
      {showEmail && (
        <Email>
          vær ikke genert, slay og send en email til{" "}
          <a href={"mailto:" + email}>{email}</a>
        </Email>
      )}
      <div style={{ height: "5px" }}></div>
    </>
  );
}

export default Isa;
