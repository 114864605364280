import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./Fonts/Isa.ttf";
import Category from "./Pages/Categories/Category/Category";
import Categories from "./Pages/Categories/Categories";
import Recipe from "./Pages/Recipe/Recipe";
import SearchResults from "./Pages/SearchResults/SearchResults";
import About from "./Pages/About/About";

import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from "react-router-dom";
import Minderfrafremtiden from "./Pages/About/minderfrafremtidenvisual/Minderfrafremtiden";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Categories />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "minderfrafremtiden",
    element: <Minderfrafremtiden />,
  },
  {
    path: "category",
    children: [
      {
        path: ":categoryKey",
        element: <Category />,
      },
    ],
  },
  {
    path: "recipe",
    children: [
      {
        path: ":recipeKey",
        element: <Recipe />,
      },
    ],
  },
  {
    path: "find",
    children: [
      {
        path: ":query",
        element: <SearchResults />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
