import search from "./Search.png";
import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SearchButtonContainer = styled.div`
  width: 10%;
  height: 60%;
  position: relative;
  padding-left: 5vw;
`;
const SearchButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const SearchButton = ({ showSearch, doShowSearch }) => {
  const [showStateOnMouseDown, setShowStateOnMouseDown] = useState(false); // we only want to show if the searchbox was hidden on mouseDown - otherwise it was hidden by blur event
  return (
    <SearchButtonContainer>
      <SearchButtonWrapper
        onClick={() => {
          if (!showStateOnMouseDown) {
            doShowSearch();
          }
        }}
        onMouseDown={() => {
          setShowStateOnMouseDown(showSearch);
        }}
      >
        <img src={search} />
      </SearchButtonWrapper>
    </SearchButtonContainer>
  );
};

const SearchBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SearchBoxWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
  > input {
    width: 60vw;
    @media (max-width: 500px) {
      width: 70vw;
    }
    height: 48px;
    font-size: 1.5rem;
    margin-left: 32px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 1px solid gray;
    background-color: transparent;
    font-family: "Montserrat";
  }
  > input:focus {
    :focus {
      outline: none;
    }
  }
  img {
    position: absolute;
    top: 50%;
    -ms-transform: translate(-0%, -50%);
    transform: translate(0%, -50%);
  }
`;

export const SearchBox = ({ onHide }) => {
  const navigate = useNavigate();
  const handleSearch = (input) => {
    const searchString = input.target.value;
    if (!searchString) {
      onHide();
    } else {
      navigate("/find/" + searchString);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event);
    }
  };
  return (
    <SearchBoxContainer>
      <SearchBoxWrapper>
        <img src={search} />
        <input
          type={"text"}
          onBlur={onHide}
          onKeyDown={handleKeyPress}
          autoFocus
        ></input>
      </SearchBoxWrapper>
    </SearchBoxContainer>
  );
};
