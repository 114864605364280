import styled from "styled-components";
import Header from "../../Components/Header/Header";

import { Link, useParams } from "react-router-dom";
import { Heading, LinkWrapper } from "../../Components/CommonStyles";
import {
  getAllCategories,
  getCategoryImage,
} from "../../Data/Categories/CategoryData";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { recipeSearch } from "../../Data/Recipes/RecipeData";

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const Page = styled.div`
  padding: 30px;
  display: block;
  font-family: "Isa";
  h2 {
    font-size: 57px;
    font-weight: normal;
  }
  p {
    font-size: 40px;
  }
`;

const ResultLine = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem 1rem 0 0;
`;

function SearchResults() {
  let params = useParams();
  const { foundByRecipe, foundByIngredient } = recipeSearch(params.query);

  return (
    <>
      <Header />
      <Center>
        <Page>
          {foundByRecipe.length > 0 && <Heading level={1}>Opskrifter</Heading>}
          {foundByRecipe.map((recipe, index) => {
            return (
              <LinkWrapper key={recipe.key} to={"/recipe/" + recipe.key}>
                <ResultLine>
                  <img width={"64px"} height={"64px"} src={recipe.image_2} />
                  <Heading level={3} wiggleColor={recipe.color}>
                    {recipe.title}
                  </Heading>
                </ResultLine>
              </LinkWrapper>
            );
          })}
          {foundByIngredient.length > 0 && (
            <Heading level={1}>Ingredienser</Heading>
          )}
          {foundByIngredient.map((recipe, index) => {
            return (
              <div key={"i" + recipe.key}>
                <LinkWrapper to={"/recipe/" + recipe.key} key={recipe.key}>
                  <Heading level={5} wiggleColor={recipe.color}>
                    {recipe.title}
                  </Heading>
                </LinkWrapper>
              </div>
            );
          })}
        </Page>
      </Center>
    </>
  );
}

export default SearchResults;
