import { CATEGORY_KEYS, IUNIT, PUNIT } from "../Constants";
import bagt_havregroed_2 from "./images/morgenmad/bagt.havregrød_2.jpeg";
import bagt_havregroed from "./images/morgenmad/bagt.havregrød.jpeg";

import brombærsmoothie_2 from "./images/morgenmad/brombærsmoothie_2.jpeg";
import brombærsmoothie from "./images/morgenmad/brombærsmoothie.jpeg";
import plantemælk_2 from "./images/morgenmad/plantemælk_2.jpeg";
import plantemælk from "./images/morgenmad/plantemælk.jpeg";
import granola_2 from "./images/morgenmad/granola_2.jpeg";
import granola from "./images/morgenmad/granola.jpeg";
import fe_chiagrød_2 from "./images/morgenmad/fe.chiagrød_2.jpeg";
import fe_chiagrød from "./images/morgenmad/fe.chiagrød.jpeg";
import smoothieskål_2 from "./images/morgenmad/smoothie_2.jpeg";
import smoothieskål from "./images/morgenmad/smoothie.jpeg";

import havrepandekager_2 from "./images/morgenmad/pandekager_2.jpeg";
import havrepandekager from "./images/morgenmad/pandekager.jpeg";

import chiasyltetøj_2 from "./images/morgenmad/chia.syltetøj_2.jpeg";
import chiasyltetøj from "./images/morgenmad/chia.syltetøj.jpeg";
export const morgenmad = [
  {
    key: "bagt_havregroed",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: "bagt havregrød",
    image_2: bagt_havregroed_2,
    image_3: bagt_havregroed,
    description: {
      dk: "Denne havregrød er perfekt til kolde efterårs morgener, hvor man har god tid til morgenmaden. Opskriften kan nemt ganges op og bages i et større fad, hvis du vil overraske hele familien, eller have rester til den travle hverdag.",
    },
    size: { amount: 4, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "små æbler" },
        alternative: { amount: 1, unit: "count", description: { dk: "stort" } },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "finvalsede havregryn" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "chiafrø" },
        alternative: { description: { dk: "knuste hørfrø" } },
      },
      {
        amount: 2.5,
        unit: IUNIT.DL,
        description: { dk: "plantemælk" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "stødt kanel" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        optional: true,
        description: { dk: "stødt allehånde" },
      },
      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "bagepulver" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "plantesmør" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "salt" },
      },
    ],
    steps: [
      {
        dk: "Tænd ovnen på 180 grader. Hak nødderne groft, og rør dem sammen med ahornsirup i en lille skål.",
      },
      {
        dk: "Rør de resterende tørre ingredienser sammen i en stor skål. Smør et ovnfast fad med plantesmør.",
      },
      {
        dk: "Skær æblerne i store tern og fordel dem i fadet. Hæld grødmassen over æblerne, og pynt tilsidst med nøddetoppingen.",
      },
      {
        dk: "Bages i ovnen i 25-30 minutter, til toppen er gylden. Tjek om grøden er færdig med en strikkepind ell. lign. Server med plantemælk, friskt æble og kanelsukker.",
      },
    ],
    tips: [
      {
        dk: "Grøden kan opbevares på køl i en lufttæt beholder 2 dage, og varmes op når tiden er knap. Kan fryses.",
      },
      {
        dk: "Tip: Har du ikke æbler, kan du snildt bruge pærer, bær eller bananer i stedet.",
      },
    ],
  },

  {
    key: "plantemealk",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: "plantemælk",
    image_2: plantemælk_2,
    image_3: plantemælk,
    description:
      "Denne plantemælk har det milde fra havren og det cremede fra nødderne. Sammen bliver det til den lækreste plantemælk. Kom den på din morgenmad, i din the, smoothie eller hvor du ellers plejer at bruge mælk.  ",
    size: { amount: 1, unit: "liter" },
    ingredients: [
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "havregryn" },
      },
      {
        amount: 1,
        unit: IUNIT.L,
        description: { dk: "vand" },
      },

      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "valgfri nødder" },
      },

      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "daddel" },
      },
    ],
    steps: [
      {
        dk: "kom gryn, nødder, daddel og vand i en blender og lad det stå og trække i 40-60 min. ⁣",
      },
      {
        dk: "Efter det har stået, blendes det i 3-5 min.⁣ ",
      },

      {
        dk: "⁣Hæld det gennem en fin si eller vrid det gennem et rent viskestykke, ned i et glas der kan lukkes tæt ",
      },
    ],
    tips: ["holder 4-6 dage på køl"],
  },
  {
    key: "chiasoltetoej",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: "chia syltetøj",
    image_2: chiasyltetøj_2,
    image_3: chiasyltetøj,
    description:
      "denne syltetøj er sødet med dadler, og er langt mere næringsrig end hvad du kan finde på hylden i supermarkedet. Du kan både bruge frosne og friske bær, alt efter hvad du har til rådighed.",
    size: { amount: 2, unit: "glas" },
    ingredients: [
      {
        amount: 200,
        unit: IUNIT.G,
        description: { dk: "hindbær" },
      },
      {
        amount: 100,
        unit: IUNIT.G,
        description: { dk: "blåbær" },
      },

      {
        amount: 8,
        unit: IUNIT.COUNT,
        description: { dk: "medjool dadler" },
      },

      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "chiafrø" },
      },

      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "vand" },
      },

      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "citronsaft" },
      },
    ],
    steps: [
      {
        dk: "Kom bær, vand og og mosede dadler i en lille gryde og lad det komme op at koge kortvarigt.  ",
      },
      {
        dk: "Skru ned for varmen og tilsæt chiafrøene imens du rører rundt. ",
      },

      {
        dk: "Tag gryden af varmen, smag til med citronsaft og lad syltetøjet køle en smule ned inden du kommer det i et skoldet glas. ",
      },

      {
        dk: "opbevares på køl i op til en uge.",
      },
    ],
    tips: [],
  },
  {
    key: "lyn-granola",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: "lyn-granola",
    image_2: granola_2,
    image_3: granola,
    description:
      "At lave granola fra bunden kan føles som en lidt langsommelig proces, men hvis du hurtigt ønsker at få granola på bordet, er denne opskrift en god løsning. ",
    size: { amount: 1, unit: "glas" },
    ingredients: [
      {
        amount: 4,
        unit: IUNIT.DL,
        description: { dk: "havregryn" },
      },
      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "kokosmel" },
      },
      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "hørfrø" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "mandler" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "rapsolie" },
      },
      {
        amount: 4,
        unit: IUNIT.TBLSP,
        description: { dk: "ahornsirup" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "salt" },
      },
    ],
    steps: [
      {
        dk: "Rist kokosmel, hørfrø og mandler på en pande i et par minutter. Rør ofte. ",
      },
      {
        dk: "Tilsæt havregryn, olie og sirup og rist videre og rør næsten konstant. ",
      },

      {
        dk: "Sluk varmen og tilsæt salt og krydderier. ",
      },

      {
        dk: "Lad din granola køle før du kommer den på glas",
      },
    ],
    tips: [],
  },
  {
    key: "fe-chiagrød",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: "fe chiagrød",
    image_2: fe_chiagrød_2,
    image_3: fe_chiagrød,
    description: "",
    size: { amount: 1, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 1.6,
        unit: IUNIT.DL,
        description: { dk: "mandelmælk" },
      },
      {
        amount: 1.5,
        unit: IUNIT.TBLSP,
        description: { dk: "chiafrø" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "grovvalsede havregryn" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "frosne hindbær" },
      },
      {
        amount: 0.5,
        unit: IUNIT.COUNT,
        description: { dk: "æble" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "sirup" },
      },
    ],
    steps: [
      {
        dk: "Tø hindbærende op i et højt glas eller skål ",
      },
      {
        dk: "Tilsæt mandelmælk, chiafrø, havregryn og sirup og bland det hele sammen.⁣",
      },

      {
        dk: "Dæk skålen/glasset til og set det på køl i minimum 3 timer, gerne natten over. ",
      },

      {
        dk: "Servér med lige præcis den topping du har lyst til. ",
      },
    ],
    tips: [],
  },

  {
    key: "smoothie skål",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: "smoothie skål",
    image_2: smoothieskål_2,
    image_3: smoothieskål,
    description:
      "Denne smoothie smager skønt af bær og sommer. Ofte tilføjer jeg lidt frossen broccoli til mine smoothies, da det ikke kan smages, men giver lidt ekstra næring. ",
    size: { amount: 1, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "frosne jordbær" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "frosne hindbær" },
      },

      {
        amount: 0.5,
        unit: IUNIT.COUNT,
        description: { dk: "frossen banan" },
      },

      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "frossen broccoli" },
      },

      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "plantemælk" },
      },

      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "planteyoghurt" },
      },
    ],
    steps: [
      {
        dk: "Kom alle ingredienser i en blender og kør indtil du får en glat konsistens. Hvis du synes at konsistensen er for fast, så tilsæt lidt mere plantemælk. ",
      },
      {
        dk: "Top den med lige hvad du har lyst til. Prøv f.eks. med kokosflager, banan eller granola. ",
      },
    ],
    tips: [
      "Har du ikke sojayoghurt, kan du bruge plantemælk +  ½ tsk citronsaft i stedet.",
    ],
  },
  {
    key: "havrepandekager",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: "havrepandekager",
    image_2: havrepandekager_2,
    image_3: havrepandekager,
    description:
      "Da jeg var mindre, lavede min far tit pandekager i weekenden. Imens stod jeg ved siden af på en skammel og spurgte “Hvornår er de færdige? Kan jeg vende dem nu?” Nu er det min lillebror som ivrigt står ved siden af mig og vender halvfærdige pandekager.",
    size: { amount: 15, unit: "små pandekager", addSubtract: 5 },
    ingredients: [
      {
        amount: 1.5,
        unit: IUNIT.DL,
        description: { dk: "havregryn" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "hvedemel" },
      },

      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "banan" },
      },

      {
        amount: 2.5,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },

      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "chiaæg: 1 spsk chiafrø + 3 spsk vand" },
      },

      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "vaniljesukker" },
      },

      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "kanel" },
      },

      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "allehånde" },
      },

      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "bagepulver" },
      },

      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "citronsaft" },
      },

      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "hasselnødder" },
      },
    ],
    steps: [
      {
        dk: "Start med at røre chiafrø og vand sammen i en lille skål. Efter 5 minutters tid danner det en gelé.  ",
      },
      {
        dk: "Kom alle ingredienser på nær hasselnødder i en blender, og blend til en ensartet masse. Lad dejen stå i min 20 min for at samle sig.",
      },
      {
        dk: "Hak hasselnødderne groft og vend dem i dejen.",
      },
      {
        dk: "Varm en pande op til lidt over medium varme med lidt rapsolie. Bag pandekagerne til de er gyldne på begge sider.",
      },
    ],
    tips: [],
  },
  {
    key: "frisk brombær smoothie",
    categoryKeys: [CATEGORY_KEYS.MORGENMAD],
    title: " brombær smoothie",
    image_2: brombærsmoothie_2,
    image_3: brombærsmoothie,
    description:
      "Denne smoothie er inspireret af en af de skønne opskrifter i green kitchen stories’ smukke smoothie bog. Denne version er mere enkel og er færdig på få minutter. ⁣",
    size: { amount: 1, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "frosne brombær" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "frosne hindbær" },
      },
      {
        amount: 0.5,
        unit: IUNIT.COUNT,
        description: { dk: "banan" },
      },
      {
        amount: 2.5,
        unit: IUNIT.DL,
        description: { dk: "plantemælk" },
      },
    ],
    steps: [
      {
        dk: "Blend bær, banan og plantemælk og smag evt. til med citronsaft",
      },
      {
        dk: "Sérver med dine yndlinsgtoppings. ⁣Her har jeg brugt skovsyre, rawnola og ristede mandler. ⁣ ",
      },
    ],
    tips: [],
  },
];
