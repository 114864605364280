import styled from "styled-components";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Header from "../../../Components/Header/Header";
import { Heading, LinkWrapper } from "../../../Components/CommonStyles";
import { recipeDataByCategory } from "../../../Data/Recipes/RecipeData";
import { categoryDataByKey } from "../../../Data/Categories/CategoryData";
import { Footer } from "../../../Components/Footer/Footer";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Page = styled.div`
  clear: both;
  display: block;
  padding-top: 50px;
  font-family: "Isa";
  max-width: 1100px;
  h2 {
    font-size: 57px;
    font-weight: normal;
  }
  p {
    max-width: 600px;
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 1.8rem;
    @media (min-width: 800px) {
      font-size: 2rem;
    }
  }
`;

const RecipeCardContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5%;
  justify-content: center;
  width: 100%;
`;

const RecipeCardWrapper = styled.div`
  width: 40vw;
  @media (min-width: 1240px) {
    width: 512px;
  }
`;

const SimpleHeader = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  @media (min-width: 800px) {
    font-weight: 100;
  }
  margin-top: 3vw;

  font-size: max(18px, 3.8vw);
  @media (min-width: 1240px) {
    font-size: 46px;
  }
  white-space: nowrap;
`;

const RoundedImage = styled.img`
  border-radius: 2%;
  margin-top: 5%;
  margin-bottom: 5%;
`;

const RecipeCard = ({ recipe, category }) => {
  const { key, title, image_2, description } = recipe;

  return (
    <LinkWrapper to={"/recipe/" + key} key={key}>
      <RecipeCardWrapper>
        <Center>
          <SimpleHeader level={2} xxlineColor={category.color}>
            {title}
          </SimpleHeader>
        </Center>
        <RoundedImage
          alt={description.dk}
          src={image_2}
          width={"100%"}
          height={"100%"}
        />
      </RecipeCardWrapper>
    </LinkWrapper>
  );
};

function Category() {
  let params = useParams();
  const category = categoryDataByKey[params.categoryKey];
  const { title, color, description } = category;
  const recepies = recipeDataByCategory[category.key] || [];
  return (
    <Center>
      <Header />
      <Page>
        <Center>
          <div>
            <Heading level={1} wiggleColor={color}>
              {title}
            </Heading>
          </div>
          <div style={{ marginLeft: "5%", marginRight: "5%" }}>
            <p>{description}</p>
          </div>
        </Center>
        <Center>
          <RecipeCardContainer>
            {recepies.map((recipe) => {
              return (
                <RecipeCard
                  key={recipe.key}
                  recipe={recipe}
                  category={category}
                />
              );
            })}
          </RecipeCardContainer>
        </Center>
      </Page>
      <Footer />
    </Center>
  );
}

Category.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
};

export default Category;
