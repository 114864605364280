import React from "react";
import { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkWrapper = styled(Link)`
  text-decoration: none;
  color: black;
`;

export const Images = styled.div`
  clear: both;
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Hr = styled.hr`
  clear: both;
  width: 100%;
  border: 0px;
  border-bottom: 2px solid #d5e7e7;
  margin-top: ${(props) => (props.paddingTop ? props.paddingTop : "48px")};
  margin-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : "48px"};
`;

const HeadingStyles = styled.h1`
  font-family: ${(props) => (props.font ? props.font : "Montserrat")};
  font-style: normal;
  font-weight: 200;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "50px")};
  border-bottom: ${(props) =>
    props.lineColor ? "2px solid " + props.lineColor : "none"};
  margin-top: 6px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "6px"};
`;

export const SiteTitle = styled(HeadingStyles)`
  padding-top: 6px;
  padding-left: 38px;
  padding-right: 30px;
  font-size: 64px;
  letter-spacing: 0.12em;
`;

const Container = styled.div`
  display: table;
`;

export const Wiggle = ({ color, width }) => {
  const viewBox = "0 0 " + (width > 287 ? 287 : width) + " 19";
  return (
    <svg
      viewBox={viewBox}
      fill="none"
      width={width}
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9.5C15.4762 19.5 28.9524 19.5 42.4286 9.5C55.9048 -0.5 69.381 -0.5 82.8571 9.5C96.3333 19.5 109.81 19.5 123.286 9.5C136.762 -0.5 150.238 -0.5 163.714 9.5C177.19 19.5 190.667 19.5 204.143 9.5C217.619 -0.5 231.095 -0.5 244.571 9.5C258.048 19.5 271.524 19.5 285 9.5"
        stroke={color}
        strokeOpacity="0.7"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
};

const HeadingWrapper = styled.div`
  display: inline-block;

  .child {
    display: flex;
  }
  .child div {
    flex-grow: 1;
    width: 0;
  }

  svg {
    position: relative;
    top: -0.75rem;
  }
`;

const fontSizes = [54, 44, 32, 24, 18];
const fontSizesIsa = [70, 55, 40, 32, 26];

export const SimpleHeader = ({ text, font, level, marginBottom }) => {
  return (
    <HeadingWrapper>
      <HeadingStyles
        fontSize={
          (font === "Isa" ? fontSizesIsa[level - 1] : fontSizes[level - 1]) +
          "px"
        }
        font={font}
        marginBottom={marginBottom}
      >
        {text}
      </HeadingStyles>
      <div className="child">
        <div>
          <svg
            viewBox="0 0 193 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 6C11 0.666667 20 0.666667 29 6C38 11.3333 47 11.3333 56 6C65 0.666667 74 0.666667 83 6C92 11.3333 101 11.3333 110 6C119 0.666667 128 0.666667 137 6C146 11.3333 155 11.3333 164 6C173 0.666667 182 0.666667 191 6"
              stroke="#D5E7E7"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    </HeadingWrapper>
  );
};

export const InnerHeading = ({
  level,
  font,
  children,
  lineColor,
  wiggleColor,
  marginBottom,
}) => {
  const ref = useRef(null);
  const [headerWidth, setHeaderWidth] = useState(0);
  useLayoutEffect(() => {
    setHeaderWidth(ref.current.offsetWidth);
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setHeaderWidth(ref.current.offsetWidth);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Container>
      <div ref={ref}>
        <HeadingStyles
          fontSize={
            (font === "Isa" ? fontSizesIsa[level - 1] : fontSizes[level - 1]) +
            "px"
          }
          font={font}
          lineColor={lineColor}
          marginBottom={marginBottom}
        >
          {children}
        </HeadingStyles>
      </div>

      {wiggleColor && <Wiggle width={headerWidth + 1} color={wiggleColor} />}
    </Container>
  );
};

export const Heading = React.memo(InnerHeading);
