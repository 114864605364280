import { useLocalStorage } from "../../Hooks/UseLocalStorage";
import Checkbox from "./Checkbox";
import styled from "styled-components";

const StepWrapper = styled.div`
  margin-bottom: 1em;
  display: flex;
  & > div {
    margin: 6px 6px 6px 0px;
  }
`;

const CheckboxWrapper = styled.div`
  width: 24px;
  height: 24px;
  padding-top: 4px;
  position: relative;
  top: -6px;
  left: -9px;
  background-color: white;
`;

export const Line = styled.div`
  width: 3px;
  float: left;
  position: relative;
  left: 11px;
  top: 28px;

  margin-top: 8px;
  margin-bottom: 24px;
  z-index: -2;
`;

export const Step = ({ description, uniqueKey }) => {
  const localStorageKey = "sc_" + uniqueKey;

  const [checked, setChecked] = useLocalStorage(localStorageKey, false);
  return (
    <StepWrapper>
      <Line>&nbsp;</Line>
      <CheckboxWrapper>
        <Checkbox
          checked={checked}
          onClick={() => {
            setChecked(!checked);
          }}
        />
      </CheckboxWrapper>

      <div style={{ color: checked ? "gray" : "inherit" }}>{description}</div>
    </StepWrapper>
  );
};
