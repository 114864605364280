import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Menu from "./Menu.png";

const MenuButtonContainer = styled.div`
  width: 10%;
  height: 60%;
  position: relative;
  padding-right: 5vw;
`;
const MenuButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const MenuButton = () => {
  const navigate = useNavigate();
  return (
    <MenuButtonContainer>
      <MenuButtonWrapper
        onClick={() => {
          navigate("/about");
        }}
      >
        <img src={Menu} />
      </MenuButtonWrapper>
    </MenuButtonContainer>
  );
};
