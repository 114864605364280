import React from "react";
import { useState } from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  width: 25px;
`;

// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Checkbox = ({ className, checked, ...props }) => {
  return (
    <CheckboxContainer className={className} {...props}>
      <HiddenCheckbox checked={checked} {...props} />

      <svg width="100%" height="100%" viewBox="0 0 16 16">
        <circle
          cx="8"
          cy="8"
          r="7"
          stroke={"#B4D8D5"}
          strokeWidth="1"
          fill={checked ? "#B4D8D5" : "white"}
        />
      </svg>
    </CheckboxContainer>
  );
};

export default Checkbox;
