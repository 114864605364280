import { Image, Images } from "../../../Components/CommonStyles";
import img19 from "./images/Rectangle 19.jpg";
import img20 from "./images/Rectangle 20.jpg";
import img21 from "./images/Rectangle 21.jpg";
import img22 from "./images/Rectangle 22.jpg";

function GrønneStunder() {
  return (
    <>
      <h2>om grønne stunder</h2>

      <p>
        Idéen til grønne stunder startede i sommeren 2021, og spirede lige så
        langsomt i løbet af efterårsmånederne. Abstrakte forestillinger om et
        sted der ville samle de veganske opskrifter jeg har udviklet i løbet af
        de seneste par år, i et eventyrligt og simpelt design.
      </p>
      <p>
        Min hensigt med hjemmesidens design har været at skabe overblik og glæde
        i køkkenet. Jeg tror på at verden har brug for mere ro og
        langsommelighed, og at madlavning og måltider kan være et par af de
        brikker i vores hverdag, der kan bidrage til dette. Selv øver jeg mig i
        at finde ro i de praktiske hverdagssysler, og se opvasken som en
        mulighed for at give tankerne frirum, og ikke blot endnu en ting der
        skal overstås.
      </p>
      <p>
        For mange bliver mad lig tal og regler; den naturlighed vi havde som
        børn, erstattes af en mistro til vores egen krops signaler og behov. Mit
        håb er, at jeg ved de små anekdoter ved opskrifterne, kan bidrage til en
        fortælling om mad som værende en måde at udtrykke omsorg på, ved at nære
        dem vi holder af og os selv. Noget vi kan samle os om og hvor vi ved, at
        det både gavner vores krop, sind såvel som verden omkring os.
      </p>
      <Images>
        <div>
          <Image src={img19} />
        </div>
        <div>
          <Image src={img20} />
        </div>
        <div>
          <Image src={img22} />
        </div>
        <div>
          <Image src={img21} />
        </div>
      </Images>
    </>
  );
}

export default GrønneStunder;
