import styled from "styled-components";
import { ReactComponent as LogoWithText } from "./LogoWithText.svg";
import banner from "./WaveBannerCentered.png";
import leafThingy from "./leaf thingy.svg";

import { Link, ScrollRestoration } from "react-router-dom";
import { useState } from "react";
import { SearchBox, SearchButton } from "./Search";
import { MenuButton } from "./Menu";
import { useMediaQuery } from "react-responsive";

const Background = styled.div`
  height: 100px;
  @media (min-width: 800px) {
    height: 210px;
  }
  position: relative;
  width: 100%;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  background-image: url(${banner});
  background-position: bottom 0% left 50%;
  background-repeat: repeat-x;
`;

const Center = styled.div`
  height: 55%;
  padding-left: 5%;
  padding-right: 5%;
  width: 64%;
`;

const Divider = styled.div`
  content: "";
  height: 40%; //You can change this if you want smaller/bigger borders
  width: 1px;

  background-color: gray; // The color of your border

  position: relative;
  top: 15%;
  -ms-transform: translate(0, 0);
  transform: translate(0, -20%);
`;

const MobileHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
  background-image: url(${banner});
  background-position: bottom 0% left 60%;
  background-repeat: repeat-x;

  height: 105px;
`;

const LogoWithTextWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

const LogoText = styled.div`
  font-family: "Montserrat";
  font-size: 38px;
  font-weight: 100;
`;

const MobileHeaderSearchWrapper = styled.div`
  display: flex;
  padding-top: 1.5rem;
  padding-right: 1rem;
`;

function Header() {
  const [showSearch, setShowSearch] = useState(false);
  const singleColumn = useMediaQuery({
    query: "(max-width: 500px)",
  });
  return singleColumn ? (
    <MobileHeaderContainer>
      <ScrollRestoration />

      {showSearch ? (
        <SearchBox
          onHide={() => {
            setShowSearch(false);
          }}
        />
      ) : (
        <Link to={"/"} style={{ textDecoration: "none", color: "black" }}>
          <LogoWithTextWrapper>
            <img src={leafThingy} />

            <LogoText>Grønne stunder</LogoText>
          </LogoWithTextWrapper>
        </Link>
      )}

      {!showSearch && (
        <MobileHeaderSearchWrapper>
          <SearchButton
            showSearch={showSearch}
            doShowSearch={() => setShowSearch(true)}
          />
        </MobileHeaderSearchWrapper>
      )}
    </MobileHeaderContainer>
  ) : (
    <Background>
      <ScrollRestoration />
      <MenuButton />
      <Divider />
      <Center>
        {showSearch ? (
          <SearchBox
            onHide={() => {
              setShowSearch(false);
            }}
          />
        ) : (
          <Link to={"/"} style={{ textDecoration: "none", color: "black" }}>
            <LogoWithText width={"100%"} height={"100%"} />
          </Link>
        )}
      </Center>
      <Divider />

      {
        <SearchButton
          showSearch={showSearch}
          doShowSearch={() => setShowSearch(true)}
        />
      }
    </Background>
  );
}

export default Header;
