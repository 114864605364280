import { CATEGORY_KEYS, IUNIT, PUNIT } from "../Constants";
import emilspandekager_2 from "./images/påeventyr/emils.pandekager_2.jpeg";
import emilspandekager from "./images/påeventyr/emils.pandekager.jpeg";

import tunsalat_2 from "./images/påeventyr/tunsalat_2.jpeg";
import tunsalat from "./images/påeventyr/tunsalat.jpeg";

import romkugler_2 from "./images/påeventyr/romkugler_2.jpeg";
import romkugler from "./images/påeventyr/romkugler.jpeg";

import rnæringsrigemuffins_2 from "./images/påeventyr/rnæringsrigemuffins_2.jpeg";
import rnæringsrigemuffins from "./images/påeventyr/rnæringsrigemuffins.jpeg";

import forårsruller from "./images/påeventyr/veit.forårsruller.jpeg";
import forårsruller_2 from "./images/påeventyr/veit.forårsruller_2.jpeg";

export const Påeventyr = [
  {
    key: "vietnamesiske forårsruller",
    categoryKeys: [CATEGORY_KEYS.EVENTYR],
    title: "vietnamesiske forårsruller",
    image_2: forårsruller_2,
    image_3: forårsruller,
    description:
      "En lækker frisk ret der egner sig godt til picnics, da de nydes kolde.",
    size: { amount: 8, unit: PUNIT.COUNT },

    ingredients: [
      {
        amount: 8,
        IUNIT: IUNIT.COUNT,
        description: { dk: "Rispapir" },
      },
      {
        amount: 175,
        unit: IUNIT.G,
        description: { dk: "seitan eller tofu" },
      },
      {
        amount: 4,
        unit: IUNIT.COUNT,
        description: {
          dk: "Blandede grøntsager, fx. gullerøder, rødkål, agurk. Men benyt endelig muligheden for at få tømt ud i grøntsagskuffen. Evt. korriander hvis du synes om det.",
        },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "sorte sesamfrø" },
      },

      {
        subtitle: "satay sauce",
        ingredients: [
          {
            amount: 5,
            unit: IUNIT.TBLSP,
            description: { dk: "peanutbutter" },
          },
          {
            amount: 1,
            unit: IUNIT.TBLSP,
            description: { dk: "sirup" },
          },
          {
            amount: 1,
            unit: IUNIT.TBLSP,
            description: { dk: "soya" },
          },
          {
            amount: 1,
            unit: IUNIT.SMIDGEN,
            description: { dk: "chili" },
          },
          {
            amount: 3,
            unit: IUNIT.TBLSP,
            description: { dk: "vand" },
          },
          {
            amount: 1.5,
            unit: IUNIT.TBLSP,
            description: { dk: "limejuice" },
          },
        ],
      },
      {
        subtitle: "seitan marinade",
        ingredients: [
          {
            amount: 2,
            unit: IUNIT.TBLSP,
            description: { dk: "vand" },
          },
          {
            amount: 2,
            unit: IUNIT.TBLSP,
            description: { dk: "peanutbutter" },
          },
          {
            amount: 1,
            unit: IUNIT.TBLSP,
            description: { dk: "miso eller soya" },
          },
          {
            amount: 1,
            unit: IUNIT.count,
            description: { dk: "fed hvidløg" },
          },
          {
            amount: 1,
            unit: IUNIT.TSP,
            description: { dk: "finthakket ingefær" },
          },
        ],
      },
    ],
    steps: [
      {
        dk: "til satay saucen kommes alle ingredienser i et glas med låg og ryst indtil massen er ensartet. Smag til, måske mangler den salt, syre eller sødme",
      },
      {
        dk: "Kom alle ingredienser til seitan marinaden i en skål og bland det sammen med en gaffel. Virker massen for fast, så tilføj lidt ekstra vand. ",
      },
      {
        dk: "Skær seitan i tynde aflange skiver, og vend dem i marinaden. Du kan lade dette stå og mariniere, eller stege det med det samme.",
      },
      {
        dk: "Varm en pande med olie og steg din seitan til det er crispy. Imens kan du snitte grøntsagerne.",
      },
      {
        dk: "Snit dine grøntsager i tynde og aflange skiver. ",
      },
      {
        dk: "Kog ca. 2 dl vand og hæld det i et en tallerken.",
      },
      {
        dk: "Tag et stykke rispapir og læg det ned i fadet med vand. Lad det ligge cirka 20 sekunder. Pas på med at ladde det ligge for lidt.",
      },
      {
        dk: "Tag rispapiret op med begge hænder så det ikke klistre sammen, og læg det på et skærebræt ",
      },
      {
        dk: "drys med sesamfrø og evt. korriander",
      },
      {
        dk: "Læg nu seitan og grøntsager ind på midten af rispapiret i en stribe.",
      },
      {
        dk: "Hold fast om fyldet imens du ruller stramt sammen og folder siderne ind.",
      },
      {
        dk: "Servér dem sammen med satay sauce og sød chilisauce.",
      },
    ],
    tips: [],
  },

  {
    key: "mini_romkugler",
    categoryKeys: [CATEGORY_KEYS.EVENTYR],
    title: "mini romkugler",
    image_2: romkugler_2,
    image_3: romkugler,
    description: "næringsrige romkugler med dadler og havregryn ",
    size: { amount: 30, unit: IUNIT.COUNT },
    ingredients: [
      {
        amount: 400,
        unit: IUNIT.G,
        description: { dk: "dadler" },
      },
      {
        amount: 100,
        unit: IUNIT.G,
        description: { dk: "havregryn" },
      },
      {
        amount: 50,
        unit: IUNIT.G,
        description: { dk: "hasselnødder" },
      },
      {
        amount: 40,
        unit: IUNIT.G,
        description: { dk: "valnødder" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "chiafrø" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "vand" },
      },
      {
        amount: 25,
        unit: IUNIT.G,
        description: { dk: "kakaopulver" },
      },
      {
        amount: 3,
        unit: IUNIT.tsk,
        description: { dk: "rom essens" },
      },
    ],
    steps: [
      {
        dk: "Kom alle ingredienser i en foodporccesor og blend til du opnår en ensartet masse.",
      },
      {
        dk: "Lad evt massen køle ned, inden du former romkuglerne. ",
      },
      {
        dk: "Tag en stor tsk romkuglemasse, og tril dem til små kugler. Tril dem i kokosmel, havregryn eller krymmel. ",
      },
      {
        dk: "Opbevares på køl. ",
      },
    ],
    tips: [],
  },
  {
    key: "vegansk tunsalat",
    categoryKeys: [CATEGORY_KEYS.EVENTYR],
    title: "vegansk t'nsalat",
    image_2: tunsalat_2,
    image_3: tunsalat,
    description:
      "Perfekt til rugbrødsmaden, sandwichen eller som topping til små kanapéer.",
    size: { amount: 5, unit: IUNIT.DL },
    ingredients: [
      {
        amount: 10,
        unit: IUNIT.DL,
        description: { dk: "kogte kikærter" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "majs" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "neutral vegansk yoghurt" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "vegansk mayo" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "mellem str. rødløg" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "citronsaft" },
      },
      {
        amount: 60,
        unit: IUNIT.G,
        description: { dk: "kapers, samt væsken fra glasset" },
      },
      {
        amount: 1 / 4,
        unit: IUNIT.TSP,
        description: { dk: "salt og peber" },
      },
    ],
    steps: [
      {
        dk: "Snit rødløg og kapers fint. ",
      },
      {
        dk: "Kom alle ingredienserne, på nær majs, i en foodproccesor. Pas på med at blende dem for meget, vi vil gerne have at t’nsalaten har lidt tekstur.",
      },
      {
        dk: "Vend majsene i. ",
      },
      {
        dk: "Opbevares på køl i op til en uge.",
      },
    ],
    tips: [],
  },
  {
    key: "Emils_pandekager",
    categoryKeys: [CATEGORY_KEYS.EVENTYR],
    title: "emils pandekager",
    image_2: emilspandekager_2,
    image_3: emilspandekager,
    description:
      "Jeg fandt på denne opskrift en dag hvor jeg fik min 3 årige fætter på besøg. Det kan ofte være svært at få nok næring i børn, samtidig med at man giver dem noget de godt kan lide. Her er mit bud på et næringsrigt mellemmåltid, testet og afprøvet af Emil ",
    size: { amount: 18, unit: "persons" },
    ingredients: [
      {
        amount: 4,
        unit: IUNIT.DL,
        description: { dk: "havregryn" },
      },
      {
        amount: 6,
        unit: IUNIT.COUNT,
        description: { dk: "dadler" },
      },
      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "valnødder" },
      },
      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "hasselnødder" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "hørfrø" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "soyamælk" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "salt" },
      },
    ],
    steps: [
      {
        dk: "Kom alle ingredienser i en blender, og blend til du opnår en glat masse. Lad gerne dejen stå lidt inde du begynder at bage.",
      },
      {
        dk: "Kom dej på en opvarmet pande med olie. Forsøg at få dem ca. 7 cm i diameter. ",
      },
      {
        dk: "Bag til pandekagerne er gyldne på hver side. ",
      },
    ],
    tips: [{ dk: "" }],
  },
  {
    key: "næringsrige muffins",
    categoryKeys: [CATEGORY_KEYS.EVENTYR],
    title: "næringsrige muffins",
    image_2: rnæringsrigemuffins_2,
    image_3: rnæringsrigemuffins,
    description:
      "Når du smager disse muffins, vil du ikke tro, at de kun er sødet med frugt og hverken indeholder æg eller smør. Den krydrede smag fra kardemomme og kanel, den luftige krumme takket være hørfrøægget. Ingredienslisten sørger for, at de ikke kun kan nydes til fest, men også til morgenmad eller et sødt mellemmåltid.",
    size: { amount: 14, unit: "stk" },
    ingredients: [
      {
        amount: 100,
        unit: IUNIT.G,
        description: { dk: "mandelmel" },
      },
      {
        amount: 120,
        unit: IUNIT.G,
        description: { dk: "havremel" },
      },
      {
        amount: 50,
        unit: IUNIT.G,
        description: { dk: "boghvedemel, eller havremel" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "bagepulver" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "stødt kardemomme" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "stødt kanel" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "stødt nelikke, kan udelades" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "vaniljesukker" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "40 g hørfrø + 4 dl vand" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "mandler, hakket groft, til pynt" },
      },
    ],
    steps: [
      {
        dk: "Hørfrøæg: Kom vand og hørfrø i en gryde og bring det i kog. Lad det koge i omkring 3 minutter, under konstant omrøring. Massen bør nu være gelé-agtig. Hæld massen gennem en si, ned i en bred skål. Lad det køle af min. 30 min. før brug. ",
      },

      {
        dk: "Hvis du ikke allerede har mandel og havremel, så start med at køre mandlerne og havregrynene igennem i en foodproccesor. ",
      },

      {
        dk: "Tænd ovnen på 200 grader. ",
      },

      {
        dk: "Bland de tørre ingredienser sammen i en skål og sæt det til side.  ",
      },

      {
        dk: "Blend de våde ingredienser sammen til en glat masse med en blender eller stavblender. ",
      },

      {
        dk: "Vend forsigtigt de våde ingredienser sammen med de tørre. Pas på med at røre for meget, da fugtigheden kan blive påvirket. ",
      },

      {
        dk: "Placer papirmuffinforme i en muffinform af metal eller silikone.  Er det silikoneforme kan du nøjes med at smøre formen med lidt rapsolie.",
      },

      {
        dk: "Fordel dejen ligeligt, og drys med grofthakkede mandler",
      },

      {
        dk: "Bages i ovnen i 20 minutter, eller indtil de er gyldne. Tjek om de er gennembagte med en strikkepind eller lign. Sidder der lidt kagedej på, skal de have lidt længere.",
      },

      {
        dk: "Kan fryses ned. ",
      },
    ],
    tips: [{ dk: "" }],
  },
];
