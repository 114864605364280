import styled from "styled-components";
import Header from "../../Components/Header/Header";

import { Link } from "react-router-dom";
import {
  getAllCategories,
  getCategoryImage,
} from "../../Data/Categories/CategoryData";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Footer } from "../../Components/Footer/Footer";

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Page = styled.div`
  padding: 2rem;
  @media (max-width: 500px) {
    padding: 1rem;
  }
  max-width: 900px;
  font-family: "Isa";
  h2 {
    font-size: 57px;
    font-weight: normal;
  }
  p {
    font-size: 2em;
  }
`;

const SectionLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SectionImage = styled.img`
  max-width: 510px;
`;

const SectionHeaderImage = styled.img`
  max-width: min(max(250px, 50vw), 510px);
`;

const SectionDescription = styled.div`
  width: 40%;
  max-width: 80vw;

  padding: 5%;
`;

const MobileSectionDescription = styled.div`
  width: 90%;
  padding: 5%;
`;

function DesktopSection({ category, index }) {
  const { key, description } = category;
  const isEven = index % 2 === 0;

  return (
    <SectionLink to={"/category/" + key} key={key}>
      <SectionWrapper>
        {isEven && (
          <SectionImage
            width={"40%"}
            height={"40%"}
            src={getCategoryImage(key)}
          />
        )}
        <SectionDescription>
          <SectionHeaderImage src={getCategoryImage(key + "Header")} />

          <p>{description}</p>
        </SectionDescription>

        {!isEven && (
          <SectionImage
            width={"40%"}
            height={"40%"}
            src={getCategoryImage(key)}
          />
        )}
      </SectionWrapper>
    </SectionLink>
  );
}

function MobileSection({ category }) {
  const { key, description } = category;

  return (
    <SectionLink to={"/category/" + key} key={key}>
      <MobileSectionDescription>
        <SectionHeaderImage
          width={"100%"}
          src={getCategoryImage(key + "Header")}
        />
        <SectionImage width={"100%"} src={getCategoryImage(key)} />
        <br></br>

        <p>{description}</p>
      </MobileSectionDescription>
    </SectionLink>
  );
}
function Categories() {
  const [categories, setCategories] = useState([]);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 700px)",
  });
  useEffect(() => {
    getAllCategories().then((data) => setCategories(data));
  }, []);

  return (
    <>
      <Header />

      <Center>
        <Page>
          {categories.map((category, index) => {
            return isDesktopOrLaptop ? (
              <DesktopSection
                key={category.key}
                category={category}
                index={index}
              />
            ) : (
              <MobileSection
                key={category.key}
                category={category}
                index={index}
              />
            );
          })}
        </Page>
      </Center>
      <Footer />
    </>
  );
}

export default Categories;
