import phoneImage from "./Minder fra fremtiden.png";
import computerImage from "./computer  - Minder fra fremtiden.png";
import { useMediaQuery } from "react-responsive";

function MinderFraFremtiden() {
  const isComputer = useMediaQuery({
    query: "(min-width: 600px)",
  });
  return (
    <img
      src={isComputer ? computerImage : phoneImage}
      width={"100%"}
      alt={"Indlæser minder"}
    ></img>
  );
}

export default MinderFraFremtiden;
