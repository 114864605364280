import { morgenmad } from "./Morgenmad";
import { japansk } from "./Japansk";
import { Påeventyr } from "./PåEventyr";
import { Hovedretter } from "./Hovedretter";
import { sødt } from "./Sødt";
import { Drikke } from "./Drikke";

export const recipeData = [
  ...sødt,
  ...morgenmad,
  ...japansk,
  ...Påeventyr,
  ...Hovedretter,
  ...Drikke,
];

export const recipeDataByCategory = recipeData.reduce((map, recipe) => {
  recipe.categoryKeys.forEach((categoryKey) => {
    if (!map[categoryKey]) {
      map[categoryKey] = [];
    }
    map[categoryKey].push(recipe);
  });
  return map;
}, {});

export const recipeDataByKey = recipeData.reduce((map, recipe) => {
  map[recipe.key] = recipe;
  return map;
}, {});

const wholeWordBlacklist = ["mel"];
const matches = (searchString, searchMaterial) => {
  if (wholeWordBlacklist.includes(searchString) || searchString.length < 3) {
    return new RegExp("\\b" + searchString + "\\b").test(searchMaterial);
  } else {
    return searchMaterial.includes(searchString);
  }
};

export const recipeSearch = (searchString) => {
  const foundByRecipe = [];
  const foundByIngredient = [];

  recipeData.forEach((recipe) => {
    const recipeSearch =
      recipe.title + " " + (recipe?.description["dk"] || recipe.description);
    const ingredientSearch = recipe.ingredients
      .map((i) => i.description?.dk)
      .join(" ");

    if (matches(searchString.toLowerCase(), recipeSearch.toLowerCase())) {
      foundByRecipe.push(recipe);
    }
    if (matches(searchString.toLowerCase(), ingredientSearch.toLowerCase())) {
      foundByIngredient.push(recipe);
    }
  });
  return { foundByRecipe, foundByIngredient };
};
