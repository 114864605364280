import { CATEGORY_KEYS, IUNIT, PUNIT } from "../Constants";
import japanske_sommernudler from "./images/japansk/japanske.sommernudler.jpeg";
import japanske_sommernudler_2 from "./images/japansk/japanske.sommernudler-kopi_2.jpeg";

import miso_auberginer from "./images/japansk/misoauberginer.jpeg";
import miso_auberginer_2 from "./images/japansk/misoauberginer_2.jpeg";
import soya_bønner_2 from "./images/japansk/soya.bønner_2.jpeg";
import soya_bønner from "./images/japansk/soya.bønner.jpeg";
import japansk_spidskålsalat_2 from "./images/japansk/japansk.spidskålsalat_2.jpeg";
import japansk_spidskålsalat from "./images/japansk/japansk.spidskålsalat.jpeg";
import stegteris_2 from "./images/japansk/stegte.ris_2.jpeg";
import stegteris from "./images/japansk/stegte.ris.jpeg";

import unigiri_2 from "./images/japansk/unigiri_2.jpeg";
import unigiri from "./images/japansk/unigiri.jpeg";

import tempura_2 from "./images/japansk/tempura_2.jpeg";
import tempura from "./images/japansk/tempura.jpeg";
import shiitake_2 from "./images/japansk/shiitake_2.jpg";
import shiitake from "./images/japansk/shiitake.jpg";

export const japansk = [
  {
    key: "Aubergine med miso",
    categoryKeys: [CATEGORY_KEYS.JAPANSK],
    title: "miso aubergine",
    image_2: miso_auberginer_2,
    image_3: miso_auberginer,
    description: "umamifyldt sideret eller blot som tilbehør til ris ",
    size: { amount: 4, unit: "persons" },
    ingredients: [
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "aubergine" },
      },
      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "miso" },
      },

      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "sukker" },
      },

      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "revet ingefær" },
      },
      {
        amount: 0.4,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },
    ],
    steps: [
      {
        dk: "Auberginerne skæres i tynde strimler og lægges i vand i 10-20 min.Derefter drænes de.",
      },
      {
        dk: "Steg auberginerne til de er bløde. Tilsæt sukkeret og rør rundt.  ",
      },
      {
        dk: "Til sidst røres revet ingefær, miso og vand i.  ",
      },
    ],
    tips: [],
  },
  {
    key: "Grønne soya bønner",
    categoryKeys: [CATEGORY_KEYS.JAPANSK],
    title: "grønne soya bønner",
    image_2: soya_bønner_2,
    image_3: soya_bønner,
    description: "en lækker sideret til ethvert japansk måltid. ",
    size: { amount: 4, unit: "persons" },
    ingredients: [
      {
        amount: 250,
        unit: IUNIT.G,
        description: { dk: "grønne bønner" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "soya" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "sukker" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "ristet sesamolie" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "sesamfrø" },
      },
    ],
    steps: [
      {
        dk: "Skær enderne af de grønne bønner og steg ved høj varme i 5 minutter. ",
      },
      {
        dk: "Skru ned på medium varme ",
      },

      {
        dk: "Tilsæt soya, sukker, sesamfrø og sesamolie og steg under hyppig omrøring i yderligere 3 minutter.  ",
      },
    ],
    tips: [],
  },
  {
    key: "Tempura",
    categoryKeys: [CATEGORY_KEYS.JAPANSK],
    title: "tempura",
    image_2: tempura_2,
    image_3: tempura,

    description:
      "klassisk crispy japansk sideret, der kan laves med en variation af grøntsager",
    size: { amount: 8, unit: "unit" },
    ingredients: [
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "løg" },
      },
      {
        amount: 3,
        unit: IUNIT.COUNT,
        description: { dk: "kartofler" },
      },

      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "mel" },
      },

      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },

      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "gærflager, kan udelades" },
      },

      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "salt" },
      },

      {
        amount: 0.25,
        unit: IUNIT.TSP,
        description: { dk: "peber" },
      },
      {
        amount: 0.8,
        unit: IUNIT.DL,
        description: { dk: "olie" },
      },
    ],
    steps: [
      {
        dk: "Skær løg og kartofler i tynde strimler. ",
      },
      {
        dk: "Bland løg og kartofler med de resterende ingredienser i en skål med mel og vand indtil det har en sammenhængende masse. ",
      },

      {
        dk: "Kom kun lige nok mel i til at grøntsagerne hænger sammen. ",
      },

      {
        dk: "Varm olien op på panden ved høj varme og steg din tempura til de er gyldne på hver side.  ",
      },
    ],
    tips: [],
  },
  {
    key: "japansk spidskålsalat ",
    categoryKeys: [CATEGORY_KEYS.JAPANSK],
    title: "japansk kålsalat ",
    image_2: japansk_spidskålsalat_2,
    image_3: japansk_spidskålsalat,
    description: "et frisk og sprødt pust til enhver japansk hovedret. ",
    size: { amount: 4, unit: "persons" },
    ingredients: [
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "spidskål" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "sorte sesamfrø" },
      },
      {
        amount: 1.5,
        unit: IUNIT.TBLSP,
        description: { dk: "risvineddike" },
      },

      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "vand" },
      },

      {
        amount: 1.5,
        unit: IUNIT.TBLSP,
        description: { dk: "ristet sesamolie" },
      },

      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "soya" },
      },

      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "fed hvidløg" },
      },
    ],
    steps: [
      {
        dk: "Rør alle ingredienser til dressingen sammen i en lille skål og lad det trække.",
      },
      {
        dk: "Snit imens spidskålen fint.  ",
      },

      {
        dk: "Kom spidskålen i en stor skål, og masser dressingen ind i kålen med dine hænder i et minuts tid. Dette nedbrydere nogle af spidskålens fibre, samtidig med at dressingen bliver bedre integreret. ",
      },

      {
        dk: "Drys til sidst med sorte sesamfrø.",
      },
    ],
    tips: [],
  },
  {
    key: "Japanske sommernudler",
    categoryKeys: [CATEGORY_KEYS.JAPANSK],
    title: "sommernudler",
    image_2: japanske_sommernudler_2,
    image_3: japanske_sommernudler,
    description:
      "Denne nuddelret er perfekt til varme sommerdage, hvor alt man har lyst til er noget koldt og forfriskende.",
    size: { amount: 4, unit: "persons" },
    ingredients: [
      {
        amount: 5,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "dashi pulver" },
      },

      {
        amount: 0.4,
        unit: IUNIT.DL,
        description: { dk: "sukker" },
      },

      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "eddike" },
      },

      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "ristet sesamolie" },
      },

      {
        amount: 1.5,
        unit: IUNIT.COUNT,
        description: { dk: "agurk" },
      },

      {
        amount: 3,
        unit: IUNIT.COUNT,
        description: { dk: "tomater" },
      },

      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "dåse majs" },
      },

      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "gulerødder" },
      },

      {
        amount: 400,
        unit: IUNIT.G,
        description: { dk: "tynde nudler" },
      },
      {
        amount: 150,
        unit: IUNIT.G,
        description: { dk: "vegansk pålæg" },
      },

      {
        amount: 5,
        unit: IUNIT.COUNT,
        description: { dk: "breve nori tang" },
      },
    ],
    steps: [
      {
        dk: "Kom vand, soya, dashi, sesamolie og sukker i en gryde. Lad det komme op at koge kortvarigt og rør indtil sukkeret er opløst. ",
      },
      {
        dk: "Sæt suppen til side for at køle af. Når suppen er kold tilsættes eddiken.",
      },

      {
        dk: "Skær agurk, tomat og gulerødder i tynde aflange skiver. Kom majsene i en skål. Klip nori-brevene i strimler og kom dem i en skål.",
      },

      {
        dk: "Kog nudlerne efter pakkens anvisning og skyl dem efterfølgende med koldt vand. ",
      },

      {
        dk: "Servér alle komponenter i skåle..  ",
      },
    ],
    tips: ["Hæld suppen i et fad for en hurtigere nedkøling."],
  },
  {
    key: "Onigiri med spicy avocadofyld",
    categoryKeys: [CATEGORY_KEYS.JAPANSK],
    title: "onigiri",
    image_2: unigiri_2,
    image_3: unigiri,
    description:
      "Onigiri er en typisk frokost i Japan der kan spises alene, eller som element i en bentoboks. De kan købes individuelt i convenience stores Japan rundt, med forskellige former for fyld.",
    size: { amount: 3, unit: "COUNT" },
    ingredients: [
      {
        amount: 360,
        unit: IUNIT.G,
        description: { dk: "kogte japanske ris" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "eddike" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "avocado, i tern" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "forårsløg, finthakket" },
      },

      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "soya" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "ristet sesamolie" },
      },

      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "chili" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "1 spsk eddike og 2 spsk vand til formning" },
      },

      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "blad nori tang" },
      },
    ],
    steps: [
      {
        dk: "Bland de japanske ris og eddike sammen med fingrene.",
      },

      {
        dk: "Bland forsigtigt avocado, forårsløg, soya, sesamolie og stødt chili sammen i en skål. Forsøg at bevar avocadoternene. ",
      },

      {
        dk: "Dyp fingrende i din eddike-blanding, og start med at forme en trekant af ris på ca. 7 cm i højden, og 1,5 cm i tykkelse. Tryk risene i midten af trekanten lidt ned, så der er plads til avocado fyldet. ",
      },

      {
        dk: "Lig nu en stor tsk fyld der hvor du gjorde plads. ",
      },

      {
        dk: "Lav endnu en trekant i samme størrelse. Sørg for at dyppe fingrene i eddikeblandingen, så er risene langt nemmere at arbejde med. ",
      },

      {
        dk: "Placer nu forsigtigt denne trekant ovenpå den med fyldet. Brug fingrene til at lukke de to trekanter sammen. Glat overfladen ud med eddike blandingen. ",
      },

      {
        dk: "Klip nu tre strimler nori-tang på 3x8 cm. Placer dem som vist på billedet.  ",
      },

      {
        dk: "Nydes med det resterende af avocado-fyldet og evt. lidt soya",
      },
    ],
    tips: [],
  },
  {
    key: "Ingefær og shiitake tilbehør",
    categoryKeys: [CATEGORY_KEYS.JAPANSK],
    title: "ingefær og shiitake tilbehør",
    image_2: shiitake_2,
    image_3: shiitake,
    description: "spicy og umami-fyldt tilbehør ",
    size: { amount: 1, unit: "glas" },
    ingredients: [
      {
        amount: 75,
        unit: IUNIT.G,
        description: { dk: "ingefær" },
      },
      {
        amount: 45,
        unit: IUNIT.G,
        description: { dk: "shiitake svampe" },
      },

      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },

      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "dashi" },
      },

      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "soya" },
      },

      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "sukker" },
      },

      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "sake" },
      },
    ],
    steps: [
      {
        dk: "Ingefæren skrælles og skæres meget tynde strimler og vaskes grundigt i vand.",
      },
      {
        dk: "Vand sættes i kog, og ingefæren kommes i. Koges i 2-3 min. Gentag processen 3 gange, og skift vandet ud hver gang. Dette vil tage den stærke smag fra Ingefæren ",
      },
      {
        dk: "Shiitake vaskes og stilkene tages af. Skæres til tynde skiver. ",
      },
      {
        dk: " Ingefæren steges  i 4-5 min. ",
      },
      {
        dk: "Shiitake og tang tilsættes, steg i yderligere 2 minutetr. ",
      },
      {
        dk: "dashi pulver hældes over sammen med vandet. Hold under omrøring indtil vandet næsten er væk.  ",
      },
      {
        dk: "soya og sukker tilsættes og koges videre indtil der ikke er mere væske tilbage. ",
      },
    ],
    tips: [],
  },
  {
    key: "stegte ris",
    categoryKeys: [CATEGORY_KEYS.JAPANSK, CATEGORY_KEYS.HOVEDRETTER],
    relatedRecipeKeys: ["Tempura"],
    title: "stegte ris",
    image_2: stegteris_2,
    image_3: stegteris,
    description:
      "Det asiatiske køkken vil altid have en speciel plads hos mig, da min mormor er japaner. Lige siden jeg var helt lille, har jeg fået risboller, japansk curry og nori tang. Med denne opskrift vil jeg hylde de japanske smage, og takke min mormor for at have introduceret mig for japansk madlavning.",
    size: { amount: 5, unit: PUNIT.persons },
    ingredients: [
      {
        amount: 400,
        unit: IUNIT.G,
        description: { dk: "kogte japanske ris" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "broccoli, også stokken" },
      },
      {
        amount: 0.25,
        unit: IUNIT.COUNT,
        description: { dk: "blomkål" },
      },
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "gulerødder" },
      },
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "rødløg" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "rød chili" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "tommel frisk ingefær" },
      },
      {
        amount: 250,
        unit: IUNIT.G,
        description: { dk: "fast tofu" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "soya" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "miso" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "sesamolie" },
      },
      {
        amount: 5,
        unit: IUNIT.TBLSP,
        description: { dk: "vand" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "eddike" },
      },
      {
        amount: 4,
        unit: IUNIT.COUNT,
        description: { dk: "fed hvidløg" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "dashi" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "forårsløg" },
      },
    ],
    steps: [
      {
        dk: "Hvis du ikke allerede har kogte ris, så start med at koge dem. ",
      },
      {
        dk: "Hvis du ikke allerede har kogte ris, så start med at koge dem. ",
      },

      {
        dk: "Hak løg, chili, ingefær og gulerod fint. ",
      },

      {
        dk: "Skær broccoli og blomkål i mundrette stykker. ",
      },

      {
        dk: "Steg grøntsagerne i en wok eller stor gryde ved høj varme i omkring 10 minutter, til de er let dampet.Imens grøntsagerne steger, kan du lave marinaden. ",
      },

      {
        dk: "Rør ingredienserne til marinaden sammen i en lille skål og sørg for at miso-pastaen er helt opløst. ",
      },

      {
        dk: "Skær tofuen i tern på ca. 2x2 cm. ",
      },

      {
        dk: "Tilsæt tofu og paprika, og steg i yderligere 2 minutter.  ",
      },

      {
        dk: "Vend de kogte ris i grøntsagerne, sammen med marinaden. ",
      },

      {
        dk: "Steg viderer i fem minutters tid, så marinaden bliver fordelt, og risene opvarmet.",
      },

      {
        dk: "Servér med ristede sesamfrø, sød chilisovs og finthakkede forårsløg.  ",
      },
    ],
    tips: [
      {
        dk: "Hvis du bruger ris der ikke lige er blevet kogte, så hver sikker på at retten er ordentlig opvarmet før servering.",
      },

      {
        dk: "Miso er en japansk paste lavet på fermenterede sojabønner. Det tilføjer en skøn salt og umami smag i asiatiske retter.",
      },
    ],
  },
];
