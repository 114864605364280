import styled from "styled-components";

const PlusMinusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32px;
  margin-right: 0.5rem;
`;

const PlusMinus = ({ number, setNumber, addSubtract }) => {
  const addOrSubtract = addSubtract || 1;
  const increase = () => {
    setNumber(number + addOrSubtract);
  };
  const decrease = () => {
    if (number > 1) setNumber(number - addOrSubtract);
  };
  return (
    <PlusMinusWrapper>
      <div
        onClick={increase}
        style={{ width: "100%", margin: "0px", lineHeight: "0px" }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 46 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46 23C46 19.9796 45.4051 16.9888 44.2492 14.1983C43.0934 11.4078 41.3992 8.87229 39.2635 6.73654C37.1277 4.6008 34.5922 2.90663 31.8017 1.75077C29.0112 0.594912 26.0204 -1.32026e-07 23 0C19.9796 1.32026e-07 16.9888 0.594913 14.1983 1.75077C11.4078 2.90663 8.87229 4.6008 6.73654 6.73655C4.6008 8.87229 2.90663 11.4078 1.75077 14.1983C0.594912 16.9888 -2.64052e-07 19.9796 0 23L23 23H46Z"
            fill="#B4D8D5"
          />
          <path
            d="M22.2186 9.27849C22.3745 9.12422 22.6255 9.12422 22.7814 9.27849L30.9019 17.3157C31.1559 17.5671 30.9779 18 30.6205 18H14.3795C14.0221 18 13.8441 17.5671 14.0981 17.3157L22.2186 9.27849Z"
            fill="white"
            fillOpacity="0.8"
          />
        </svg>
      </div>
      <div
        onClick={decrease}
        style={{ width: "100%", margin: "0px", lineHeight: "0px" }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 23 46 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 23C0 26.0204 0.594913 29.0112 1.75077 31.8017C2.90663 34.5922 4.6008 37.1277 6.73655 39.2635C8.87229 41.3992 11.4078 43.0934 14.1983 44.2492C16.9888 45.4051 19.9796 46 23 46C26.0204 46 29.0112 45.4051 31.8017 44.2492C34.5922 43.0934 37.1277 41.3992 39.2635 39.2635C41.3992 37.1277 43.0934 34.5922 44.2492 31.8017C45.4051 29.0112 46 26.0204 46 23L23 23L0 23Z"
            fill="#D4E8E7"
          />

          <path
            d="M22.7814 36.7215C22.6255 36.8758 22.3745 36.8758 22.2186 36.7215L14.0981 28.6843C13.8441 28.4329 14.0221 28 14.3795 28H30.6205C30.9779 28 31.1559 28.4329 30.9019 28.6843L22.7814 36.7215Z"
            fill="white"
            fillOpacity="0.8"
          />
        </svg>
      </div>
    </PlusMinusWrapper>
  );
};

export default PlusMinus;
