import styled from "styled-components";
import Header from "../../Components/Header/Header";
import GrønneStunder from "./GrønneStunder/GrønneStunder";
import Isa from "./Isa/Isa";
import { JacobKuniko } from "./JacobKuniko";

const Center = styled.div`
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const Page = styled.div`
  max-width: 1050px;
  padding: 1rem;
  @media (max-width: 560px) {
    padding: 0.5rem;
  }

  display: block;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  p {
    padding-right: 2rem;
  }

  h2 {
    font-family: Montserrat;
    font-size: 50px;
    font-weight: 200;
    line-height: 1em;
    letter-spacing: 0.12em;
  }
  h3 {
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 200;
    line-height: 1em;
    margin-bottom: 45px;
    letter-spacing: 0.12em;
  }
`;

export const Email = styled.div`
  text-align: center;
  a,
  a:link,
  a:visited,
  a:focus,
  a:hover,
  a:active {
    color: black;
    text-decoration: none;
  }
`;

export const Section = styled.div`
  clear : both;
  break;
`;

function About() {
  return (
    <>
      <Header />
      <Center>
        <Page>
          <Isa />
          <GrønneStunder />
          <JacobKuniko />

          <Section>
            <div
              style={{
                marginTop: "4rem",
                marginBottom: "4rem",
                textAlign: "center",
              }}
            >
              <h3>kontakt</h3>
              <Email>
                mail:{" "}
                <a href={"mailto:IsaCarstens@gmail.com"}>
                  IsaCarstens@gmail.com
                </a>
              </Email>
              <p>instagram: Isa.Matsushima</p>
            </div>
          </Section>
        </Page>
      </Center>
    </>
  );
}

export default About;
