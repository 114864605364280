import styled from "styled-components";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import {
  Heading,
  Hr,
  Images,
  SimpleHeader,
} from "../../Components/CommonStyles";
import { recipeDataByKey } from "../../Data/Recipes/RecipeData";
import { getCategoryDataByKey as getCategoryDataByKey } from "../../Data/Categories/CategoryData";
import { PUNIT } from "../../Data/Constants";
import { Ingredient } from "./Ingredient";
import { Line, Step } from "./Step";
import { useState } from "react";
import PlusMinus from "./PlusMinus";
import { Tip } from "./Tip";
import { Footer } from "../../Components/Footer/Footer";
import { useMediaQuery } from "react-responsive";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    display: block;
    align-items: left;
    justify-content: left;
  }
`;

const Content = styled.div`
  max-width: 1000px;
  padding: 5%;
`;

const Page = styled.div`
  display: block;
  font-family: "Montserrat";

  p {
    max-width: 600px;
    text-align: center;
    margin: 1.2em;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
    line-height: 1.4em;
  }
  @media (max-width: 500px) {
    p {
      max-width: 100%;
      text-align: left;
    }
  }
`;

const Image = styled.img`
  margin: 3%;
  @media (max-width: 500px) {
    margin: 0;
  }
`;

const Columns = styled.div`
  display: flex;
  @media (max-width: 500px) {
    display: block;
  }
`;

const RecipeWrapper = styled.div`
  padding-top: 60px;
  @media (max-width: 500px) {
    padding-top: 1rem;
  }
`;

const Ingredients = styled.div`
  width: 35%;
  float: left;

  @media (max-width: 500px) {
    width: 100%;
    float: none;
  }
`;

const StepsAndTips = styled.div`
  width: 65%;
  float: left;
  @media (max-width: 500px) {
    margin-top: 2rem;
    width: 100%;
    float: none;
  }
`;
const Steps = styled.div`
  & > div:not(:last-child) > ${Line} {
    border-right: 3px solid #b4d8d5;
  }
  & > div:last-child > ${Line} {
    border-right: 3px solid white;
  }
`;
const Tips = styled.div``;

const PortionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const CategoryTitle = styled.p`
  letter-spacing: 0.15em;
  text-transform: lowercase;

  a + a {
    border-left: solid #bbb 1px;
  }
`;

const CategoryLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  padding-right: 6px;
  padding-left: 8px;
`;

function getSizeUnitDescription(sizeUnit, size) {
  switch (sizeUnit) {
    case PUNIT.PERSONS:
      return size > 1 ? "personer" : "person";
    case PUNIT.COUNT:
      return "stk";
    case "":
      return size > 1 ? "portioner" : "portion";
    default:
      return sizeUnit;
  }
}

function Recipe() {
  const language = "dk";

  let params = useParams();
  const recipe = recipeDataByKey[params.recipeKey];

  if (!recipe || !recipe.ingredients) {
    return (
      <>
        <Header />
        <Center>
          <Page>
            <Center>
              <p>Opskrift ikke fundet</p>
              <a href="/">Start forfra</a>
            </Center>
          </Page>
        </Center>
      </>
    );
  }

  const {
    title,
    description,
    image_3,
    size,
    ingredients,
    steps,
    tips,
    categoryKeys,
  } = recipe;

  const categories = categoryKeys.map((key) => {
    return getCategoryDataByKey(key);
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1000px)",
  });
  const singleColumn = useMediaQuery({
    query: "(max-width: 500px)",
  });

  const [customSize, setCustomSize] = useState(size ? size.amount : 0);
  return (
    <>
      <Header />
      <Center>
        <Page>
          <Content>
            <Center>
              <CategoryTitle>
                {categories.map((category) => {
                  return (
                    <CategoryLink
                      key={category.key}
                      to={"/category/" + category.key}
                    >
                      {category.title}
                    </CategoryLink>
                  );
                })}
              </CategoryTitle>

              <Heading level={1}>{title}</Heading>
              <Image src={image_3} width={singleColumn ? "100%" : "70%"} />
              <p>{description[language] || description}</p>
            </Center>
            <RecipeWrapper>
              <Columns>
                <Ingredients>
                  <SimpleHeader
                    text={"Ingredienser"}
                    font={"Isa"}
                    level={3}
                    wiggleColor={categories[0].color}
                  ></SimpleHeader>
                  {size ? (
                    <PortionWrapper>
                      <PlusMinus
                        number={customSize}
                        setNumber={setCustomSize}
                        addSubtract={size.addSubtract}
                      />
                      {customSize}{" "}
                      {getSizeUnitDescription(size.unit, customSize)}
                    </PortionWrapper>
                  ) : (
                    <></>
                  )}
                </Ingredients>
              </Columns>
              <Columns>
                <Ingredients>
                  {ingredients.map((ingredientOrSection, index) => {
                    return ingredientOrSection.subtitle ? (
                      <>
                        <Heading level={5} marginBottom={"10px"}>
                          {ingredientOrSection.subtitle}
                        </Heading>

                        {ingredientOrSection.ingredients.map(
                          (ingredient, index) => {
                            return (
                              <Ingredient
                                key={index}
                                uniqueKey={recipe.key + index}
                                customSize={customSize}
                                recipeSize={recipe.size.amount}
                                {...ingredient}
                              />
                            );
                          }
                        )}
                      </>
                    ) : (
                      <Ingredient
                        key={index}
                        uniqueKey={recipe.key + index}
                        customSize={customSize}
                        recipeSize={recipe.size.amount}
                        {...ingredientOrSection}
                      />
                    );
                  })}
                </Ingredients>
                {isDesktopOrLaptop && <Line></Line>}

                <StepsAndTips>
                  <Steps>
                    {steps.map((step, index) => {
                      return (
                        <Step
                          key={index}
                          uniqueKey={recipe.key + index}
                          description={step.dk}
                        ></Step>
                      );
                    })}
                  </Steps>
                  <Tips>
                    {tips &&
                      tips.map((tip, index) => {
                        return <Tip key={index} description={tip.dk}></Tip>;
                      })}
                  </Tips>
                </StepsAndTips>
              </Columns>
            </RecipeWrapper>

            {recipe.relatedRecipeKeys && (
              <>
                <Hr />
                <div>
                  <p style={{ margin: "0px", textAlign: "left" }}>
                    Måske vil du også synes om
                  </p>
                </div>

                <Images>
                  {recipe.relatedRecipeKeys.map((relatedRecipeKey, index) => {
                    return (
                      <div key={index}>
                        <img src={recipeDataByKey[relatedRecipeKey].image_2} />
                        <br />
                        {recipeDataByKey[relatedRecipeKey].title}
                      </div>
                    );
                  })}
                </Images>
              </>
            )}
          </Content>
        </Page>
        <Footer />
      </Center>
    </>
  );
}

Recipe.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
};

export default Recipe;
