export const CATEGORY_KEYS = {
  SOEDT: "soedt",
  MORGENMAD: "morgenmad",
  EVENTYR: "eventyr",
  HOVEDRETTER: "hovedretter",
  JAPANSK: "japansk",
  DRIKKE: "drikke",
};

export const IUNIT = {
  COUNT: "count",
  DL: "dl",
  G: "g",
  KG: "kg",
  TBLSP: "tblsp",
  TSP: "tsp",
  SMIDGEN: "smidgen",
  CAN: "can",
  L: "liter",
};

export const PUNIT = {
  PERSONS: "persons",
  COUNT: "count",
};
