import { CATEGORY_KEYS, IUNIT, PUNIT } from "../Constants";

import matcha_2 from "./images/drikke/matcha.bord_2.jpeg";
import matcha from "./images/drikke/matcha.bord.jpeg";

import chai_2 from "./images/drikke/chai_2.jpeg";
import chai from "./images/drikke/chai.jpeg";

import frappe_2 from "./images/drikke/frappe_2.jpeg";
import frappe from "./images/drikke/frappe.jpeg";

import kakaosmoothie_2 from "./images/drikke/kakao.smoothie_2.jpeg";
import kakaosmoothie from "./images/drikke/kakao.smoothie.jpeg";

export const Drikke = [
  {
    key: "Matcha latte",
    categoryKeys: [CATEGORY_KEYS.DRIKKE],
    title: "matcha latte",
    image_2: matcha_2,
    image_3: matcha,

    description:
      "Matcha er er blevet drukket i Japan siden år 1191.  Matcha fremstilles ved at nye grønne teblade dampes og tørres, for derefter at blive malet til det fine grønne pulver. Matcha findes i forskellig kvalitet, afhængig af om det er til madlavning eller til at drikke; led efter at der står “ceremonial grade” når du bruger det til drikke. ",
    size: { amount: 1, unit: "Person" },
    ingredients: [
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "matcha pulver" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "80 grader varmt vand" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "havremælk" },
      },
      {
        amount: 1,
        unit: IUNIT.G,
        description: { dk: "soyamælk" },
      },
    ],
    steps: [
      {
        dk: "Si dit matcha ned i en bred kop eller en mindre skål. ",
      },
      {
        dk: "Hæld 2 spsk. Vand over og pisk det sammen med dit bambus piskeris, til der ikke er flere klumper. ",
      },

      {
        dk: "Tilsæt gradvist det resterende vand imens du pisker.",
      },

      {
        dk: "Skum plantemælken i en mælkeskummer og hæld det over.",
      },
    ],
    tips: [{ dk: "" }],
  },
  {
    key: "chai latte",
    categoryKeys: [CATEGORY_KEYS.DRIKKE],
    title: "chai latte",
    image_2: chai_2,
    image_3: chai,
    description: "lunende krydret drik til de kolde måneder ",
    size: { amount: 4, unit: "personer" },
    ingredients: [
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "kanelstænger" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "kardemomme" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "tørret ingefær" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "løs sort the" },
      },
      {
        amount: 1,
        unit: IUNIT.L,
        description: { dk: "plantemælk" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "daddelsirup" },
      },
    ],
    steps: [
      {
        dk: "Kom alle ingredienser i en gryde og lad det simre på medium varme i 20 minutter. ",
      },
      {
        dk: "Hældes i kopper gennem en si og nydes varm. ",
      },
    ],
    tips: [{ dk: "" }],
  },
  {
    key: "kakako smoothie",
    categoryKeys: [CATEGORY_KEYS.DRIKKE],
    title: "kakao smoothie",
    image_2: kakaosmoothie_2,
    image_3: kakaosmoothie,
    description:
      "Denne smoothie har en skøn smag af chokolade og banan og indeholder forskellige mineraler som magnesium og jern. Du kan tilsætte et par isklumper for at få en mere milkshake-agtig konsistens. ",
    size: { amount: 1, unit: "person" },
    ingredients: [
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "frossen banan" },
      },
      {
        amount: 1,
        unit: IUNIT.unit,
        description: { dk: "dadel" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "plantemælk" },
      },
      {
        amount: 4,
        unit: IUNIT.unit,
        description: { dk: "isklumper" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "mørk kakaopulver" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "peanutbutter" },
      },
    ],
    steps: [
      {
        dk: "Kom alle ingredienserne i en blender og blend indtil du opnår en glat konsistens",
      },
      {
        dk: "nydes kold",
      },
    ],
    tips: [{ dk: "" }],
  },
  {
    key: "frappe",
    categoryKeys: [CATEGORY_KEYS.DRIKKE],
    title: "frappe",
    image_2: frappe_2,
    image_3: frappe,
    description: "Lækker twist på en iskaffe, sødet med dadler",
    size: { amount: 1, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 1.5,
        unit: IUNIT.DL,
        description: { dk: "havremælk" },
      },
      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },

      {
        amount: 8,
        unit: IUNIT.unit,
        description: { dk: "isklumper" },
      },

      {
        amount: 1,
        unit: IUNIT.unit,
        description: { dk: "espresso shot" },
      },

      {
        amount: 3,
        unit: IUNIT.unit,
        description: { dk: "dadler" },
      },

      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "kanel, kan udelades" },
      },
    ],
    steps: [
      {
        dk: "Kom alle ingredienser i en blender og blend. Nydes umiddelbart efter.",
      },
    ],
    tips: [
      {
        dk: "du kan tilføje et skefuld vegansk vaniljeis, for en ekstra cremet frappe",
      },
    ],
  },
];
