import { CATEGORY_KEYS, IUNIT } from "../Constants";
import kodsovs from "./images/hovedretter/k*dsovs.jpeg";
import kodsovs_2 from "./images/hovedretter/k*dsovs_2.jpeg";
import dhal_2 from "./images/hovedretter/solgul.dhal_2.jpeg";
import dhal from "./images/hovedretter/solgul.dhal.jpeg";

import pestopasta_2 from "./images/hovedretter/pesto.pasta_2.jpeg";
import pestopasta from "./images/hovedretter/pesto.pasta.jpeg";

import bygotto_2 from "./images/hovedretter/byggotto_2.jpeg";
import bygotto from "./images/hovedretter/byggotto.jpeg";

export const Hovedretter = [
  {
    key: "solgul dhal",
    categoryKeys: [CATEGORY_KEYS.HOVEDRETTER],
    title: "solgul dhal",
    image_2: dhal_2,
    image_3: dhal,
    description:
      "Dhal er en traditionel indisk ret baseret på røde linser, tomat, kokosmælk og grøntsager. Det har en skøn krydret smag, der bliver afstemt med sødmen fra æblet, kanelen og kokosmælken.",
    size: { amount: 5, unit: "persons" },
    ingredients: [
      {
        amount: 4,
        unit: IUNIT.COUNT,
        description: { dk: "fed hvidløg" },
      },
      {
        amount: 20,
        unit: IUNIT.G,
        description: { dk: "ingefær" },
      },
      {
        amount: 2,
        unit: IUNIT.unit,
        description: { dk: "løg" },
      },
      {
        amount: 2,
        unit: IUNIT.unit,
        description: { dk: "gulerødder" },
      },
      {
        amount: 2,
        unit: IUNIT.unit,
        description: { dk: "æbler" },
      },
      {
        amount: 1 / 4,
        unit: IUNIT.unit,
        description: { dk: "af et blomkåldshoved" },
      },
      {
        amount: 1,
        unit: IUNIT.CAN,
        description: { dk: "dåsetomater" },
      },

      {
        amount: 1,
        unit: IUNIT.CAN,
        description: { dk: "kokosmælk" },
      },
      {
        amount: 3,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },
      {
        amount: 3,
        unit: IUNIT.DL,
        description: { dk: "røde linser" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "citronsaft" },
      },
      {
        amount: 1,
        unit: IUNIT.unit,
        description: { dk: "kanelstang" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "spidskommen" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "koriander" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "chiliflager" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "paprika" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "stødt kardemomme" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "gurkemeje" },
      },
    ],
    steps: [
      {
        dk: "kom røde linser i en si og skyl dem grundigt med vand, sæt det til side",
      },
      {
        dk: "Hak løg, hvidløg, ingefær fint.",
      },

      {
        dk: "Skær gulerødder i tern og blomkål i passende stykker. ",
      },

      {
        dk: "Svits grøntsager, hvidløg og ingefær under høj varme sammen med krydderierne i lidt olie under omrøring i omkring 5 minutter. Tilsæt evt. lidt vand undervejs.  ",
      },

      {
        dk: "Tilsæt flåede tomater, tern af æble, vand/sojamælk, kanelstang og kokosmælk i gryden og lad det simre i 40-45 minutter. ",
      },

      {
        dk: "Smag til med salt, peber og citronsaft.",
      },
    ],
    tips: [{ dk: "" }],
  },
  {
    key: "Pasta med k*dsovs",
    categoryKeys: [CATEGORY_KEYS.HOVEDRETTER],
    title: "pasta med k*dsovs",
    image_2: kodsovs_2,
    image_3: kodsovs,
    description:
      "Denne k*dsovs smager skønt og kan varieres på mange måder. Grøntsagerne kan nemt skiftes ud, prøv eksempelvis bladselleri eller portobellosvampe.",
    size: { amount: 5, unit: "persons" },
    ingredients: [
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "rødløg" },
      },
      {
        amount: 1,
        unit: IUNIT.unit,
        description: { dk: "gulerod" },
      },
      {
        amount: 200,
        unit: IUNIT.G,
        description: { dk: "brune champignon" },
      },
      {
        amount: 0.5,
        unit: IUNIT.unit,
        description: { dk: "squash" },
      },
      {
        amount: 6,
        unit: IUNIT.G,
        description: { dk: "fed hvidløg" },
      },
      {
        amount: 2,
        unit: IUNIT.CAN,
        description: { dk: "hakkede tomater" },
      },
      {
        amount: 100,
        unit: IUNIT.G,
        description: { dk: "tørret soyagranulat" },
      },
      {
        amount: 5,
        unit: IUNIT.DL,
        description: { dk: "kogende vand" },
      },
      {
        amount: 1,
        unit: IUNIT.unit,
        description: { dk: "terning grøntsagsbouillon" },
      },
      {
        amount: 1.5,
        unit: IUNIT.TSP,
        description: { dk: "tørret oregano" },
      },
      {
        amount: 1.5,
        unit: IUNIT.TSP,
        description: { dk: "tørret basilikum, kan udelades" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "paprika" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "mørk balsamicoeddike" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "tomatpuré" },
      },
    ],
    steps: [
      {
        dk: "Hæld 5 dl. kogende vand over 100 g. sojagranulat og grøntsagsbouillon. Rør rundt, så vandet bliver absorberet.",
      },
      {
        dk: "Hak løg, gulerod, squash og champignon fint. ",
      },

      {
        dk: "Steg det i lidt olivenolie ved middelhøj varme i 7 minutters tid. Tilsæt paprika, hvidløg, salt og peber og steg i yderligere 2 minutter. ",
      },

      {
        dk: "Tilsæt hakkede tomater, tomatpuré, iblødsat sojagranulat, tørret basilikum og tørret oregano. ",
      },

      {
        dk: "Lad sovsen simre i 20-30 minutter og tilsæt til sidst mørk balsamicoeddike.",
      },

      {
        dk: "Servér med pasta.",
      },
    ],
    tips: [
      {
        dk: "kan også bruges som fyld i lasagne og pizzasnegle. Du kan evt. bruge belugalinser i stedet for sojagranulat.",
      },
    ],
  },
  {
    key: "pesto pasta",
    categoryKeys: [CATEGORY_KEYS.HOVEDRETTER],
    title: "pesto pasta",
    image_2: pestopasta_2,
    image_3: pestopasta,
    description:
      "Efterhånden kan man finde et hav af forskellige pestoer, på hylderne i supermarkedet. De fleste af dem er dog hverken økologiske eller veganske, men heldigvis er det super nemt at lave sin egen pesto.",
    size: { amount: 2, unit: "persons" },
    ingredients: [
      {
        amount: 130,
        unit: IUNIT.G,
        description: { dk: "pasta" },
      },
      {
        amount: 0.5,
        unit: IUNIT.un,
        description: { dk: "agurk" },
      },

      {
        amount: 15,
        unit: IUNIT.unit,
        description: { dk: "cherrytomater" },
      },

      {
        amount: 1,
        unit: IUNIT.unit,
        description: { dk: "håndfuld spinat" },
      },
      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "god olivenolie" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "æblecidereddike" },
      },

      {
        amount: 1.5,
        unit: IUNIT.DL,
        description: {
          dk: "ristede kerner eller nødder, jeg brugte solsikkkerner, hampefrø og cashewnødder",
        },
      },

      {
        amount: 40,
        unit: IUNIT.unit,
        description: { dk: "basilikumblade" },
      },

      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "gærflager" },
      },

      {
        amount: 3,
        unit: IUNIT.unit,
        description: { dk: "fed hvidløg" },
      },

      {
        amount: 0.25,
        unit: IUNIT.TSP,
        description: { dk: "salt" },
      },
    ],
    steps: [
      {
        dk: "Skyl basilikumbladene grundigt.",
      },
      {
        dk: "Blend alle ingredienser til pestoen sammen med en stavblender, til du får din ønskede konsistens. Smag til med salt, peber og æblecidereddike. ",
      },
      {
        dk: "Kog pastaen efter anvisningen på pakken. ",
      },
      {
        dk: "Skær agurk og cherrytomater i mindre stykker og hak spinaten groft. ",
      },
      {
        dk: "Hæld vandet fra pastaen, og lad en smule blive tilbage. Rør 6 spsk pesto i pastaen sammen med spinaten.",
      },
      {
        dk: "Servér med cherrytomater, agurk og frisk basilikum.",
      },
    ],
    tips: [
      {
        dk: "Pesto-pasta udmærker sig ved at det både kan spises varmt og koldt. ",
      },
    ],
  },
  {
    key: "bygotto med svampe",
    categoryKeys: [CATEGORY_KEYS.HOVEDRETTER],
    title: "Bygotto med svampe",
    image_2: bygotto_2,
    image_3: bygotto,
    description:
      "En skøn og varmende ret til vinterhalvåret. Bygkornene giver mere bid og næring til retten, ift hvis du brugte risottoris.",
    size: { amount: 5, unit: "persons" },
    ingredients: [
      {
        amount: 400,
        unit: IUNIT.G,
        description: { dk: "perlebyg" },
      },
      {
        amount: 60,
        unit: IUNIT.G,
        description: { dk: "tørrede svampe" },
      },
      {
        amount: 200,
        unit: IUNIT.G,
        description: { dk: "brune champignon" },
      },
      {
        amount: 2,
        unit: IUNIT.unit,
        description: { dk: "løg" },
      },
      {
        amount: 1,
        unit: IUNIT.unit,
        description: { dk: "porre" },
      },
      {
        amount: 0.5,
        unit: IUNIT.unit,
        description: { dk: "savoykål" },
      },
      {
        amount: 2,
        unit: IUNIT.unit,
        description: { dk: "hvidløg" },
      },
      {
        amount: 4,
        unit: IUNIT.DL,
        description: { dk: "frosne ærter" },
      },
      {
        amount: 9,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },
      {
        amount: 3,
        unit: IUNIT.DL,
        description: { dk: "soyamælk" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "hvidvin" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "tørret timian" },
      },
      {
        amount: 2,
        unit: IUNIT.unit,
        description: { dk: "terninger grøntsagsbouillonterninger" },
      },
      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "vegansk smør" },
      },
      {
        amount: 4,
        unit: IUNIT.TBLSP,
        description: { dk: "gærflager" },
      },
    ],
    steps: [
      {
        dk: "Kom de tørrede svampe i en skål og overhæld dem med kogende vand. ",
      },
      {
        dk: "Hak løg og porre fint og varm olivenolie op i en stor gryde. ",
      },

      {
        dk: "Løg og porre steges ved middelhøj varme i 5 minutter. ",
      },

      {
        dk: "Skær champignoner i skiver og savoykål i mindre stykker. tilføj dem til gryden. ",
      },

      {
        dk: " Hak hvidløg fint.",
      },

      {
        dk: " Tilsæt vand, sojamælk, hvidløg og grøntsagsbouillonterninger til gryden.",
      },

      {
        dk: " Lad bygottoen simrer i 30-40 min eller indtil du har opnået din ønskede konsistens. Rør jævnligt og tilsæt mere væske undervejs om nødvendigt.  ",
      },

      {
        dk: "Smag til med salt, peber og gærflager. ",
      },
    ],
    tips: [{ dk: "" }],
  },
];
