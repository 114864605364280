import styled from "styled-components";

const TipWrapper = styled.div`
  margin-bottom: 1em;
  display: flex;
  padding-left: 42px;
  @media (max-width: 500px) {
    padding-left: 0px;
  }
  background-color: white;
`;

export const Tip = ({ description }) => {
  return (
    <TipWrapper>
      <div>{description}</div>
    </TipWrapper>
  );
};
