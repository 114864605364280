import billedeAfJacob from "./images/billede af Jacob.jpg";
import billedeAfKuniko from "./images/billede af Kuniko.jpg";
export const JacobKuniko = () => {
  return (
    <>
      <div style={{ marginTop: "10rem" }}>
        <img
          src={billedeAfJacob}
          style={{
            float: "left",
            marginRight: "5rem",
            marginBottom: "2rem",
            "@media (max-width: 560px)": {
              margin: "0rem",
            },
            maxWidth: "100%",
            height: "auto",
          }}
        />
        <h3>Jacob Ludvig Carstens</h3>
        <p>
          Min far Jacob, har skrevet koden til Grønne Stunder. Uden ham, ville
          denne hjemmeside bestå af et virvar af opskrifter, samt et dokument i
          figma.
        </p>
        <p>Tak for altid at støtte mig i mine projekter</p>
      </div>
      <div style={{ break: true, marginTop: "13rem" }}>
        <img
          src={billedeAfKuniko}
          style={{
            float: "right",
            marginLeft: "5rem",
            marginBottom: "2rem",
            maxWidth: "100%",
            height: "auto",
          }}
        />
        <h3>Kuniko Matsushima Rasmussen</h3>
        <p>
          Min mormor Kuniko, har lært mig alt hvad jeg ved om japansk madlavning
          og været medforfatter på flere af de japanske opskrifter.
        </p>
        <p>
          Hun er uden tvivl en af de personer jeg kender der er bedst til at
          eksperimentere med nye opskrifter, samt finde og bruge ingredienser
          fra naturen.
        </p>
      </div>
    </>
  );
};
