import aeblecrumble_2 from "./images/soedt/aeblecrumble_2.jpg";
import aeblecrumble from "./images/soedt/aebletaerte_2-kopi.jpg";
import bagte_paerer_2 from "./images/soedt/bagtepærer.fuldstr.jpeg";
import bagte_paerer from "./images/soedt/bagte_paerer_2.jpg";

import banan_broed_2 from "./images/soedt/banan_broed_2.jpg";
import banan_broed from "./images/soedt/bananbrød.fuldstr.jpeg";

import brownie_2 from "./images/soedt/brownie_2.jpg";
import brownie from "./images/soedt/brownie_2-kopi.jpg";

import eventyrlig_lagkage_2 from "./images/soedt/eventyrlig_lagkage_2.jpg";
import eventyrlig_lagkage from "./images/soedt/eventyrlig_lagkage_2-kopi.jpg";

import pikachu_kage_2 from "./images/soedt/pikachu_kage_2.jpg";
import pikachu_kage from "./images/soedt/pikachu_kage_2-kopi.jpg";

import citron_muffins_2 from "./images/soedt/citron_muffins_2.jpg";
import citron_muffins_3 from "./images/soedt/citron_muffins_3.jpg";

import ahornbarer_2 from "./images/soedt/ahorn.barer-kopi.jpeg";
import ahornbarer from "./images/soedt/ahorn.barer.jpeg";

import granolasmåkager_2 from "./images/soedt/granola.cookies-kopi.jpeg";
import granolasmåkager from "./images/soedt/granola.cookies.jpeg";

import rabarberlimeis_2 from "./images/soedt/rabarber.is-kopi.jpg";
import rabarberlimeis from "./images/soedt/rabarber.is.jpg";

import { CATEGORY_KEYS, IUNIT, PUNIT } from "../Constants";

export const sødt = [
  {
    key: "banan_broed",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "bananbrød",
    image_2: banan_broed_2,
    image_3: banan_broed,

    description:
      "dette bananbrød egner sig godt til hverdag, eller med chokolade smørcreme til mere festlige lejligheder ",
    size: { amount: 4, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 3,
        unit: "count",
        description: { dk: "bananer" },
        alternative: { amount: 1, unit: "count", description: { dk: "stort" } },
      },
      {
        amount: 80,
        unit: IUNIT.G,
        description: { dk: "rørsukker" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "vaniljesukker" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "citronsaft" },
      },
      {
        amount: 75,
        unit: IUNIT.G,
        description: { dk: "hvedemel" },
      },
      {
        amount: 60,
        unit: IUNIT.G,
        description: { dk: "havremel" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "bagepulver" },
      },
      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "natron" },
      },
      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "salt" },
      },
      {
        amount: 90,
        unit: IUNIT.G,
        description: { dk: "vegansk smør" },
      },
      {
        amount: 45,
        unit: IUNIT.G,
        description: { dk: "valnødder" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "kanel" },
      },
    ],
    steps: [
      {
        dk: "tænd ovnen på 175 varmluft",
      },
      {
        dk: "Smelt smørret i en lille gryde og sæt til side, det skal være afkølet ved brug.",
      },
      {
        dk: "Hak valnødder groft, sæt til side.",
      },
      {
        dk: "kom mel, bagepulver, natron og salt i en stor skål. Sæt til side.",
      },
      {
        dk: "Mos bananerne og kom i stor skål sammen med sukker, vanilje og citronsaft. Pisk det godt sammen med elpisker.",
      },
      {
        dk: "Tilsæt melblandingen og vend  sammen, blot indtil dejen er homogen. Pas på ikke at røre for meget.",
      },
      {
        dk: "vend nu smør og valnødder sammen med de andre ingredienser",
      },
      {
        dk: "Bag banankagen i den forvarmede ovn ca. 35-40 min. indtil en tandstik kommer ren ud, eller med få krummer. ",
      },
    ],
    tips: [],
  },
  {
    key: "bagte_paerer",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "bagte pærer",
    image_2: bagte_paerer,
    image_3: bagte_paerer_2,

    description:
      "En utrolig simpelt dessert, der er hurtig at trylle frem. Pærens sødme bliver fremhævet ved bagningen og passer perfekt til den skønne smag af kanel. ",
    size: { amount: 4, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 4,
        unit: IUNIT.COUNT,
        description: { dk: "pærer, gerne sorten ‘conference’" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "kanel" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "ahornsirup" },
      },
      {
        amount: 30,
        unit: IUNIT.G,
        description: { dk: "hasselnødder, finthakket" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "vand" },
      },
    ],
    steps: [
      {
        dk: "Tænd ovnen på 170 grader varmluft",
      },
    ],
    tips: [],
  },
  {
    key: "brownie",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "brownie",
    image_2: brownie_2,
    image_3: brownie,

    description:
      "“Isa, når jeg kommer hjem, kan du så ikke lave din brownie?” - Sådan lød det fra min lillebror, da han var på vej hjem fra en uge på bornholm. Første gang jeg lavede brownien var til min nonfirmation, og siden er den blevet serveret til fødselsdage og andre festlige lejligheder.",
    size: { amount: 4, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 380,
        unit: IUNIT.G,
        description: { dk: "frosne bananer" },
      },

      {
        amount: 200,
        unit: IUNIT.G,
        description: { dk: "frosne hindbær" },
      },
      {
        amount: 150,
        unit: IUNIT.G,
        description: { dk: "frosne jordbær" },
      },
      {
        amount: 1.5,
        unit: IUNIT.DL,
        description: { dk: "plantemælk" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "soyayoughurt" },
      },
      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "vaniljesukker" },
      },

      {
        amount: 140,
        unit: IUNIT.G,
        description: { dk: "valnødder" },
      },
      {
        amount: 70,
        unit: IUNIT.G,
        description: {
          dk: "mørk chokolade, de fleste over 70% er veganske, men tjek for at være sikker",
        },
      },
      {
        amount: 200,
        unit: IUNIT.G,
        description: { dk: "mandler" },
      },
      {
        amount: 270,
        unit: IUNIT.G,
        description: { dk: "udstenede dadler" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "banan" },
      },
      {
        amount: 2.5,
        unit: IUNIT.DL,
        description: { dk: "plantemælk" },
      },
      {
        amount: 90,
        unit: IUNIT.G,
        description: { dk: "mørk kakaopulver" },
      },
      {
        amount: 2,
        unit: IUNIT.TBLSP,
        description: { dk: "rapsolie" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "salt" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "vaniljesukker" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "plantesmør" },
      },
    ],
    steps: [
      {
        dk: "Rør chiafrø og vand sammen i en lille skål. Efter 5 minutters tid danner det en gelé.",
      },
      {
        dk: "Hak valnødder og chokolade groft og læg det til side.",
      },
      {
        dk: "Blend chia-ægget og de resterende ingredienser sammen i en foodprocessor til en ensartet masse.",
      },
      {
        dk: "Tænd ovnen på 180 grader. Hæld dejen i en skål og vend nødder og chokolade i med en dejskraber.",
      },
      {
        dk: "Smør en ovnfast fad på ca 20 x 25 cm med plantesmør. Fordel dejen i formen og pynt evt. med valnødder. Bages i ca. 25-30 minutter.",
      },
      {
        dk: "Lad kagen køle let af og server med bær-is.",
      },
    ],
    tips: [],
  },
  {
    key: "pikachu_kage",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "pikachu kage",
    image_2: pikachu_kage_2,
    image_3: pikachu_kage,

    description: "",
    size: { amount: 1, unit: PUNIT.COUNT },
    ingredients: [
      {
        amount: 3,
        unit: IUNIT.DL,
        description: { dk: "aquafaba" },
      },
      {
        amount: 4,
        unit: IUNIT.DL,
        description: { dk: "sukker" },
      },
      {
        amount: 300,
        unit: IUNIT.G,
        description: { dk: "hvedemel" },
      },
      {
        amount: 0.6,
        unit: IUNIT.DL,
        description: { dk: "rapsolie" },
      },
      {
        amount: 175,
        unit: IUNIT.G,
        description: { dk: "hasselnødder" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "salt" },
      },
      {
        amount: 400,
        unit: IUNIT.G,
        description: { dk: "naturli smørblok" },
      },
      {
        amount: 150,
        unit: IUNIT.G,
        description: { dk: "flormelis" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "kanel" },
      },
      {
        amount: 0.5,
        unit: IUNIT.TSP,
        description: { dk: "kardemomme" },
      },
      {
        amount: 1,
        unit: IUNIT.TBLSP,
        description: { dk: "citronsaft" },
      },
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "pærer" },
      },
      {
        amount: 2,
        unit: IUNIT.COUNT,
        description: { dk: "bananer" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "ananas" },
      },
      {
        amount: 20,
        unit: IUNIT.G,
        description: { dk: "chokolade" },
      },
    ],
    steps: [
      {
        dk: "Find alle ingredienser frem og mål dem af.",
      },
      {
        dk: "Tænd ovnen på 175C. Smør springformen på 20 cm.",
      },
      {
        dk: "Hak hasselnødder groft, de må gerne være lidt forskellige størrelser.",
      },

      {
        dk: "Pisk aquafabaen let luftig med en elpisker, dette kan tage lidt tid.",
      },

      {
        dk: "Med en ske hælder du nu omkring 1 spiseske sukker ad gangen i aquafabaen, og pisker omkring ½ minut. Fortsæt med al sukkeret og pisk videre til du har en luftig masse.",
      },

      {
        dk: "Med en lille si drysser du nu omkring 1/4 af melet over marengsmassen og vender det med en dejskraber forsigtigt rundt nogle gange som demonstreret i videoen i mit indlæg. Gentag med al melet.",
      },

      {
        dk: "Hæld olien i skålen fold den forsigtigt ind i dejen.",
      },

      {
        dk: "Vend til sidst de hakkede hasselnødder i.",
      },
      {
        dk: "Held nu dejen i en smurt bageform, og lad den bage i 45-45 minutter. Den skal kun blive let lysebrune i overfladen, og hæver kun lidt op, så pas på med at give den for lang tid. Tjek med en kagenål.",
      },

      {
        dk: "Stil bundende til afkøling, og skær først i den, når de er helt kølet af.",
      },

      {
        dk: "Tag smøret ud af køleskabet og lad det få stuetemperatur.",
      },

      {
        dk: "Pisk alle ingredienserne til smørcreme sammen og stil det på køl.",
      },
      {
        dk: "Skær bananer og pærer i tynde skiver.",
      },

      {
        dk: "Når bundende er helt nedkølede, kan du skærer dem. Brug en brødkniv eller kagetråd til at dele bunden i tre lige store stykker.",
      },

      {
        dk: "Smør et lag smørcreme på den nederste bund. Placer pærerskiver over hele bunden. ",
      },
      {
        dk: "Gentag med banan på det næste lag. ",
      },
      {
        dk: "Placer den øverste del af bunden på kagen, og begyndt at smøre kagen op med den resternede smørcreme.",
      },

      {
        dk: "Stik ananasen ud med pikachuformene.",
      },

      {
        dk: "Smelt chokoladen forsigtigt og mal øjne, næse og mund med en tandstik.",
      },
      {
        dk: "Pynt med ananas-pikachu’erne og servér",
      },
    ],

    tips: ["bundende kan med fordel laves dagen i forvejen."],
  },
  {
    key: "eventyrlig_lagkage",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "eventyrlig lagkage",
    image_2: eventyrlig_lagkage_2,
    image_3: eventyrlig_lagkage,

    description:
      "Jeg laver denne lagkage hvert år til min fødselsdag, og den er et stort hit blandt gæster, gamle som unge. Med skønne sommersmage og kardemomme og kanel smørcremen er den både mild og interessant.",
    size: { amount: 4, unit: "persons" },
    ingredients: [
      {
        subtitle: "Bunde",
        ingredients: [
          {
            amount: 3,
            unit: IUNIT.DL,
            description: { dk: "aquafaba" },
          },
          {
            amount: 4,
            unit: IUNIT.DL,
            description: { dk: "sukker" },
          },
          {
            amount: 300,
            unit: IUNIT.G,
            description: { dk: "hvedemel" },
          },
          {
            amount: 0.6,
            unit: IUNIT.DL,
            description: { dk: "rapsolie" },
          },
          {
            amount: 175,
            unit: IUNIT.G,
            description: { dk: "hasselnødder" },
          },
          {
            amount: 1,
            unit: IUNIT.SMIDGEN,
            description: { dk: "salt" },
          },
          {
            amount: 400,
            unit: IUNIT.G,
            description: { dk: "naturli smørblok" },
          },
          {
            amount: 150,
            unit: IUNIT.G,
            description: { dk: "flormelis" },
          },
          {
            amount: 1,
            unit: IUNIT.TSP,
            description: { dk: "kanel" },
          },
          {
            amount: 0.5,
            unit: IUNIT.TSP,
            description: { dk: "kardemomme" },
          },
        ],
      },
      {
        subtitle: "Fyld",
        ingredients: [
          {
            amount: 1,
            unit: IUNIT.TBLSP,
            description: { dk: "citronsaft" },
          },
          {
            amount: 2,
            unit: IUNIT.COUNT,
            description: { dk: "pærer" },
          },
          {
            amount: 2,
            unit: IUNIT.COUNT,
            description: { dk: "bananer" },
          },
          {
            amount: 250,
            unit: IUNIT.G,
            description: { dk: "jordbær til pynt" },
          },
          {
            amount: 15,
            unit: IUNIT.COUNT,
            description: { dk: "spiselige blomster" },
          },
        ],
      },
    ],
    steps: [
      {
        dk: "Find alle ingredienser frem og mål dem af.",
      },
      {
        dk: "Tænd ovnen på 175C. Smør springformen på 20 cm.",
      },
      {
        dk: "Hak hasselnødder groft, de må gerne være lidt forskellige størrelser.",
      },

      {
        dk: "Pisk aquafabaen let luftig med en elpisker, dette kan tage lidt tid.",
      },

      {
        dk: "Med en ske hælder du nu omkring 1 spiseske sukker ad gangen i aquafabaen, og pisker omkring ½ minut. Fortsæt med al sukkeret og pisk videre til du har en luftig masse.",
      },

      {
        dk: "Med en lille si drysser du nu omkring 1/4 af melet over marengsmassen og vender det med en dejskraber forsigtigt rundt nogle gange som demonstreret i videoen i mit indlæg. Gentag med al melet.",
      },

      {
        dk: "Hæld olien i skålen fold den forsigtigt ind i dejen.",
      },

      {
        dk: "Vend til sidst de hakkede hasselnødder i.",
      },
      {
        dk: "Held nu dejen i en smurt bageform, og lad den bage i 45-45 minutter. Den skal kun blive let lysebrune i overfladen, og hæver kun lidt op, så pas på med at give den for lang tid. Tjek med en kagenål.",
      },
      {
        dk: "Stil bundende til afkøling, og skær først i den, når de er helt kølet af.",
      },
      {
        dk: "Tag smøret ud af køleskabet og lad det få stuetemperatur.",
      },

      {
        dk: "Pisk alle ingredienserne til smørcreme sammen og stil det på køl.",
      },
      {
        dk: "Skær bananer og pærer i tynde skiver.",
      },

      {
        dk: "Skær jordbær i halve og kvarte. Sæt frugten til side. ",
      },

      {
        dk: "Skyld forsigtigt de spiselige blomster og placer dem på et viskestykke til tøring.",
      },

      {
        dk: "Når bundende er helt nedkølede, kan du skærer dem. Brug en brødkniv eller kagetråd til at dele bunden i tre lige store stykker.",
      },

      {
        dk: "Smør et lag smørcreme på den nederste bund. Placer pærerskiver over hele bunden. ",
      },
      {
        dk: "Gentag med banan på det næste lag. ",
      },
      {
        dk: "Placer den øverste del af bunden på kagen, og begyndt at smøre kagen op med den resternede smørcreme.",
      },

      {
        dk: "Pynt med jordbær og blomster.",
      },
    ],

    tips: ["bundene kan med fordel laves dagen i forvejen."],
  },
  {
    key: "aeblecrumble",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "æble crumble",
    image_2: aeblecrumble_2,
    image_3: aeblecrumble,

    description:
      "Æblecrumble er en skøn efterårsdessert. Her viser jeg en moderne version, med mindre sukker og `bunden` i toppen. De knasende mandler og boghvedekerner skaber en skøn kontrast til de søde og saftige æbler.  ",
    size: { amount: 4, unit: PUNIT.PERSONS },
    ingredients: [
      {
        amount: 470,
        unit: IUNIT.G,
        description: { dk: "æbler, gerne madæbler" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "kanel" },
      },
      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "sukker" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "stødte nelliker" },
      },
      {
        amount: 3,
        unit: IUNIT.DL,
        description: { dk: "mandler" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "boghvedekerner" },
      },
      {
        amount: 0.5,
        unit: IUNIT.DL,
        description: { dk: "rørsukker" },
      },
      {
        amount: 4,
        unit: IUNIT.DL,
        description: { dk: "havregryn" },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "hvedemel" },
      },
      {
        amount: 1,
        unit: IUNIT.SMIDGEN,
        description: { dk: "salt" },
      },
      {
        amount: 70,
        unit: IUNIT.G,
        description: { dk: "plantesmør" },
      },
    ],
    steps: [
      {
        dk: "Skær æbler i store tern, og vend dem med sukker og kanel ",
      },
      {
        dk: "Fordel dem i et ovnfast fad på ca. 25 cm. i diameter, smurt med plantesmør.  ",
      },

      {
        dk: "Hak mandler groft, og bland dem med de resterende ingredienser. ",
      },

      {
        dk: "Brug gerne hænderne. Fordel crumblen over æblerne i fadet. ",
      },

      {
        dk: "Bages ved 185 grader varmluft i 20-25 minutter, til toppen er gylden. ",
      },

      {
        dk: "Servér med sojayoghurt eller vegansk vaniljeis.",
      },
    ],

    tips: ["Du kan også bruge pærer, eller blande en håndfuld bær i crumblen"],
  },
  {
    key: "ahorn.barer",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "ahorn barer",
    image_2: ahornbarer_2,
    image_3: ahornbarer,
    description:
      "Disse barer nydes bedst direkte fra fryseren og minder lidt om sneakers. Du kan enten riste dine egne peanuts, og tilsætte en smule salt til blandingen, eller bruge nogle der allerede er ristede og saltede. ",
    size: { amount: 25, unit: "unit" },
    ingredients: [
      {
        amount: 90,
        unit: IUNIT.G,
        description: { dk: "peanutbutter eller mandelsmør" },
      },
      {
        amount: 5,
        unit: IUNIT.TBLSP,
        description: { dk: "ahornsirup" },
      },
      {
        amount: 150,
        unit: IUNIT.G,
        description: { dk: "ristede og saltede peanuts (gem 50 g til pynt)" },
      },
      {
        amount: 10,
        unit: IUNIT.COUNT,
        description: { dk: "medjool dadler" },
      },
    ],
    steps: [
      {
        dk: "kom peanutbutter, ahornsirup og dadler i en foodprocessor og blend det til en ensartet masse.",
      },
      {
        dk: "Tilsæt 100 g peanuts og puls til de er grofthakkede. ",
      },

      {
        dk: "Fordel det i et fad og tryk de resterende 50 peanuts ned i massen. ",
      },

      {
        dk: "Kommes på frys i minimum 1 ½ time og skæres derefter i små bare på 2x3 cm. ",
      },
    ],
    tips: ["opbevares på frys i op til en måned."],
  },
  {
    key: "rabarber_limeis",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "rabarber-lime is",
    image_2: rabarberlimeis_2,
    image_3: rabarberlimeis,
    description:
      "Forfriskende is til sommervarmen, der får sin cremede konsistens fra kokosmælken. ",
    size: { amount: 0.5, unit: "liter" },
    ingredients: [
      {
        amount: 400,
        unit: IUNIT.G,
        description: { dk: "rabarber" },
      },
      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "vand" },
      },

      {
        amount: 0.5,
        unit: IUNIT.COUNT,
        description: { dk: "lime" },
      },

      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "vaniljesukker" },
      },

      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: "dåse kokosmælk" },
      },

      {
        amount: 110,
        unit: IUNIT.G,
        description: { dk: "rørsukker" },
      },
    ],
    steps: [
      {
        dk: "Skær rabarber i mindre stykker og kog det sammen med sukker og vand i 15 minutter.",
      },
      {
        dk: "Blend dette til en fin kompot og tilsæt limesaft, skal og vaniljesukker. Lad kompotten køle af. ",
      },
      {
        dk: "Pisk kokosmælk, og vend det sammen med den nedkølede rabarberkompot. ",
      },
      {
        dk: "Held massen i en beholder, og frys i minimum 6 timer. ",
      },
    ],
    tips: [],
  },
  {
    key: "granola_småkager",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "granola småkager",
    image_2: granolasmåkager_2,
    image_3: granolasmåkager,
    description:
      "Disse småkager er crunchy og milde i smagen. De er fyldt med god næring og tager ingen tid at bikse sammen",
    size: { amount: 8, unit: "units" },
    ingredients: [
      {
        amount: 75,
        unit: IUNIT.G,
        description: { dk: "boghvedekerner" },
      },
      {
        amount: 80,
        unit: IUNIT.G,
        description: { dk: "havregryn" },
      },

      {
        amount: 80,
        unit: IUNIT.G,
        description: { dk: "nødder" },
      },

      {
        amount: 20,
        unit: IUNIT.G,
        description: { dk: "kokosmel" },
      },

      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "kanel" },
      },

      {
        amount: 0.7,
        unit: IUNIT.G,
        description: { dk: "ahornsirup" },
      },
    ],
    steps: [
      {
        dk: "Boghvedekerner, havregryn og nødder køres i en foodprocessor, vigtigt at det er groft, ellers bliver teksturen i småkagerne  ikke knasende.",
      },
      {
        dk: "Hæld blandingen over i en skål sammen med ⁣kokosmel, kanel og ahornsirup.",
      },

      {
        dk: "Tænd ovnen på 160 grader varmluft, form småkagerne og bag dem i ca. 10-15 min. ",
      },

      {
        dk: "Lad småkagerne køle af. ",
      },

      {
        dk: "Småkagerne holder i flere uger, da de nærmest ikke indeholder væske",
      },
    ],
    tips: [],
  },
  {
    key: "citron_muffins",
    categoryKeys: [CATEGORY_KEYS.SOEDT],
    title: "citron muffins",
    image_2: citron_muffins_2,
    image_3: citron_muffins_3,
    description:
      "Citronmuffins er en af mine absolut yndlings kager. Krummen er porøs og luftig og de sprøde mandler og perlesukkeren der karamelliserer på toppen tilføjer knas.",
    size: { amount: 12, unit: PUNIT.COUNT, addSubtract: 3 },
    ingredients: [
      {
        amount: 100,
        unit: IUNIT.G,
        description: { dk: "mandler" },
      },
      {
        amount: 170,
        unit: IUNIT.G,
        description: { dk: "havregryn" },
      },
      {
        amount: 2,
        unit: IUNIT.TSP,
        description: { dk: "bagepulver" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "natron" },
      },
      {
        amount: 1,
        unit: IUNIT.TSP,
        description: { dk: "vaniljesukker" },
      },
      {
        amount: 80,
        unit: IUNIT.G,
        description: { dk: "sukker" },
      },
      {
        amount: 2,
        unit: IUNIT.DL,
        description: { dk: "plantemælk" },
      },
      {
        amount: 3,
        unit: IUNIT.TBLSP,
        description: { dk: "rapsolie" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: { dk: " 1 spsk chiafrø og 3 spsk vand rørt sammen" },
      },
      {
        amount: 1,
        unit: IUNIT.COUNT,
        description: {
          dk: "Citronskal og saft fra 1 stor eller to små citroner",
        },
      },
      {
        amount: 1,
        unit: IUNIT.DL,
        description: { dk: "mandler" },
      },
    ],
    steps: [
      {
        dk: "Tænd ovnen på 200 grader.",
      },
      {
        dk: "Kom de tørre ingredienser sammen i en food proceccor og blend til et fint mel.",
      },

      {
        dk: "Tilsæt nu de våde ingredienser i foodprocessoren og blend til en glat masse.",
      },

      {
        dk: "Pas på med at lade foodprocesoren køre for længe, da dette vil gøre krummen sej, fremfor luftig. ",
      },

      {
        dk: "Placer papirmuffinforme i en muffinform af metal eller silikone. Er det silikoneforme kan du nøjes med at smøre formen med lidt rapsolie.",
      },

      {
        dk: "Fordel dejen ligeligt, og drys med grofthakkede mandler og perlesukker.",
      },

      {
        dk: "Bages i ovnen i 20 minutter, eller indtil de er gyldne. Tjek om de er færdige med en strikkepind eller lign. Sidder der kagedej på, skal de have lidt længere. Lad dem køle ned, inden du tager dem ud af formene.",
      },
    ],
    tips: [{ dk: "Kan fryses ned." }],
  },
];
