import morgenmad from "./images/morgenmad.png";
import morgenmadHeader from "./images/morgenmad.svg";
import eventyr from "./images/paa_aeventyr.png";
import eventyrHeader from "./images/paa_aeventyr.svg";
import japansk from "./images/japansk.png";
import japanskHeader from "./images/japansk.svg";
import soedt from "./images/soedt.png";
import soedtHeader from "./images/soedt.svg";
import drikke from "./images/drikke.png";
import drikkeHeader from "./images/drikke.svg";
import hovedretter from "./images/hovedretter.png";
import hovedretterHeader from "./images/hovedretter.svg";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore/lite";
import { CATEGORY_KEYS } from "../Constants";

const imageByKey = {
  morgenmad: morgenmad,
  morgenmadHeader: morgenmadHeader,
  eventyr: eventyr,
  eventyrHeader: eventyrHeader,
  japansk: japansk,
  japanskHeader: japanskHeader,
  soedt: soedt,
  soedtHeader: soedtHeader,
  drikke: drikke,
  drikkeHeader: drikkeHeader,
  hovedretter: hovedretter,
  hovedretterHeader: hovedretterHeader,
};

export const getCategoryImage = (key) => {
  return imageByKey[key];
};

const categoryData = [
  {
    key: CATEGORY_KEYS.MORGENMAD,
    title: "Morgenmad",
    color: "#D6E7DD",
    description:
      "Her finder du både morgenmad til travle dage og til de rolige weekend morgener med mere tid",
  },
  {
    key: CATEGORY_KEYS.EVENTYR,
    title: "På Eventyr",
    color: "#CDEAAE",
    description: "Opskrifter til madpakken, picnics og andre små eventyr",
  },
  {
    key: CATEGORY_KEYS.HOVEDRETTER,
    title: "Hovedretter",
    color: "#B7D9D7",
    description:
      "Her finder du retter der hurtigt kan trylles frem på hverdagsaftenener, og retter der vil imponerer dine gæster",
  },
  {
    key: CATEGORY_KEYS.JAPANSK,
    title: "Japansk",
    color: "#FFF0A0",
    description:
      "Japanske retter med et moderne og plantebaseret twist. Lavet i samarbejde med Kuniko Matsushima Rasmussen",
  },
  {
    key: CATEGORY_KEYS.SOEDT,
    title: "Sødt",
    color: "#FFC49A",
    description:
      "Kager og desserter til hverdagens søde stunder og mere festlige lejligheder",
  },
  {
    key: CATEGORY_KEYS.DRIKKE,
    title: "Drikke",
    color: "#D6E7DD",
    description: "Forfriskende og lunende drikke til enhver lejlighed",
  },
];

export const getDb = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBzOS4rYNewvLLRV7EFVb9Ih8BALVD4E84",
    authDomain: "groenne-stunder.firebaseapp.com",
    projectId: "groenne-stunder",
    storageBucket: "groenne-stunder.appspot.com",
    messagingSenderId: "959666926061",
    appId: "1:959666926061:web:e02d595b62c999223f37e4",
    measurementId: "G-7Z1V8Q4ZR6",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  return getFirestore(app);
};
export const getAllCategories = async () => {
  const db = getDb();
  /* categoryData.forEach((category, index) => {
    setDoc(doc(db, "categories", category.key), {
      index: index,
      key: category.key,
      title: category.title,
      color: category.color,
      description: category.description,
    });
  }); */

  const categoriesCol = collection(db, "categories");
  const q = query(categoriesCol, orderBy("index"));
  const categoriesSnapshot = await getDocs(q);
  const categoriesDataFromDb = categoriesSnapshot.docs.map((doc) => doc.data());
  return categoriesDataFromDb;

  //return categoryData;
};

export const categoryDataByKey = categoryData.reduce((map, category) => {
  map[category.key] = category;
  return map;
}, {});

export const getCategoryDataByKey = (key) => {
  return categoryDataByKey[key];
};
