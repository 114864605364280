import chibiImage from "./Grønne_Stunder_Chibi 2.png";
import styled from "styled-components";
import { LinkWrapper } from "../CommonStyles";

const FooterWrapper = styled.div`
  padding-top: 150px;
  padding-bottom: 50px;
  width: 100%;
  text-align: center;
  font-family: "Isa";
  font-size: 1.8rem;
  img {
    max-width: 30vw;
  }
`;

export function Footer() {
  return (
    <LinkWrapper to={"/about"}>
      <FooterWrapper>
        <img src={chibiImage}></img>
        <br />
        om grønne stunder
      </FooterWrapper>
    </LinkWrapper>
  );
}
